import { useNavigate, useParams } from "react-router-dom";
import adminLogin from "../../api/admin/Login/loginAdmin";
import useUserLoggedIn from "../../hooks/useUserLoggedIn";
import { useState, useEffect } from "react";

const AdminLogin = () => {
  const navigate = useNavigate();
  const { marketId } = useParams();
  const [password, setPassword] = useState("");
  const [invalidLoginNotice, setInvalidLoginNotice] = useState(false);

  // useEffect(() => {
  //   if (useUserLoggedIn) {
  //     navigate(`/${marketId}/admin/dashboard`);
  //   }
  // }, []);

  const handleLogin = (password) => {
    const login = async () => {
      const loginRes = await adminLogin(marketId, password);

      if (loginRes.success === true) {
        const collectAndWinAdminCreds = {
          marketId,
          isloggedin: true,
          dateloggedin: new Date().toISOString(),
          token: loginRes.data.token,
          type: "admin",
        };

        // Store login credentials in local storage
        localStorage.setItem("collectAndWinAdminCreds", JSON.stringify(collectAndWinAdminCreds));
        navigate(`/${marketId}/admin/dashboard`);
      } else {
        // do an error
        setInvalidLoginNotice({
          error:
            loginRes.errorCode === "ClientLoginController.4"
              ? "Invalid password"
              : "There was an error with submitting the password",
        });
      }
    };

    login();
  };

  const handleKeyDown = (event) => {
    if (event.code === "Enter") {
      handleLogin(password);
    }
  };

  return (
    <div className="page-center animate-fade-in">
      <div className="text-center space-y-6">
        <h1>Admin login</h1>
        <div className="flex gap-4">
          {/* onKeyDown={() => handleKeyDown()} */}
          <input type="password" onChange={(e) => setPassword(e.target.value)} onKeyDown={handleKeyDown} />
          <button className="button bg-sky-600 text-white" onClick={() => handleLogin(password)}>
            Login
          </button>
        </div>
        {invalidLoginNotice && invalidLoginNotice.error && (
          <p className="text-center text-red-400 text-sm mt-4">{invalidLoginNotice.error}</p>
        )}
      </div>
    </div>
  );
};

export default AdminLogin;
