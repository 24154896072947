import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const useUserLoggedIn = () => {
  const navigate = useNavigate();
  const { marketId } = useParams(); // Extract marketId from the URL

  useEffect(() => {
    const checkLoginCredentials = () => {
      const storedCreds = localStorage.getItem("collectAndWinAdminCreds");
      if (storedCreds) {
        const { marketId: storedMarketId, isloggedin, dateloggedin, token } = JSON.parse(storedCreds);
        const dateNow = new Date();
        const dateLoggedIn = new Date(dateloggedin);
        const timeDifference = (dateNow - dateLoggedIn) / (1000 * 60 * 60); // Time difference in hours

        if (isloggedin && token && storedMarketId === marketId) {
          if (timeDifference < 34) {
            // Update dateloggedin and allow the user to stay on the page
            const updatedCreds = {
              ...JSON.parse(storedCreds),
              dateloggedin: new Date().toISOString(),
            };
            localStorage.setItem("collectAndWinAdminCreds", JSON.stringify(updatedCreds));
            return true;
          } else {
            // Clear local storage if credentials are more than 34 hours old
            localStorage.removeItem("collectAndWinAdminCreds");
            navigate(`/${marketId}`);
            return false;
          }
        } else {
          navigate(`/${marketId}`);
          return false;
        }
      } else {
        navigate(`/${marketId}`);
        return false;
      }
    };

    checkLoginCredentials();
  }, [marketId, navigate]);

  return true;
};

export default useUserLoggedIn;
