import clsx from "clsx";
import AdminLayout from "../../Layout/AdminLayout";
import { useEffect, useState, useRef } from "react";
import useUserLoggedIn from "../../hooks/useUserLoggedIn";
import { Link, useNavigate, useParams } from "react-router-dom";
import getPrizes from "../../api/admin/prizes/getPrizes";
import * as Icons from "../../partials/Icons";

const PrizesGlobal = () => {
  useUserLoggedIn();
  const navigate = useNavigate();
  const { marketId } = useParams();
  const [prizesData, setPrizesData] = useState("");

  useEffect(() => {
    const fetchPrizes = async () => {
      const getPrizesRes = await getPrizes();
      if (getPrizesRes.success) {
        setPrizesData(getPrizesRes.data);
      } else {
        setPrizesData({ error: true });
      }
    };
    fetchPrizes();
  }, []);

  return (
    <AdminLayout>
      <div className="wrapper wrapper-md page">
        <div className="section space-y-10">
          <header className="space-y-5">
            <button className="font-bold" onClick={() => navigate(-1)}>
              &lt; Back
            </button>
            <div className="flex justify-between items-center">
              <h1>Prizes</h1>
              <Link className="button bg-sky-600 text-white" to={`/${marketId}/admin/prizes/add`}>
                Add a prize
              </Link>
            </div>
          </header>
          {prizesData && prizesData.length > 0 && (
            <ul className="divide-y divide-solid">
              {prizesData
                .sort((a, b) => a.title.localeCompare(b.title))
                .map((prize, key) => {
                  return <Prize key={key} {...prize} />;
                })}
            </ul>
          )}
        </div>
      </div>
    </AdminLayout>
  );
};

const Prize = ({ identity, live, title, configs }) => {
  const listItemClasses = "flex gap-4 py-4";
  const navigate = useNavigate();
  const { marketId } = useParams();
  return (
    <li className={listItemClasses} onClick={() => navigate(`/${marketId}/admin/prizes/edit/${identity}`)}>
      {title && <span className="text-xl w-1/3">{title}</span>}

      <span className="w-1/3 flex justify-center items-end">
        <span className={clsx(live === 1 ? "text-green-600" : "text-red-600")}>
          {live === 1 ? "Enabled" : "Disabled"}
        </span>
      </span>

      <span className="w-1/3 text-right flex justify-end items-center">
        <span className="inline-block">
          <Icons.ChevronRight />
        </span>
      </span>
    </li>
  );
};

export default PrizesGlobal;
