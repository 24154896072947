import AdminLayout from "../../Layout/AdminLayout";
import useUserLoggedIn from "../../hooks/useUserLoggedIn";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import getEvents from "../../api/admin/events/getEvents";
import getPrizes from "../../api/admin/prizes/getPrizes";
import getGlobalPages from "../../api/admin/pages/getGlobalPages";
import * as Icons from "../../partials/Icons";

const AdminDashboard = () => {
  useUserLoggedIn();
  const navigate = useNavigate();
  const { marketId } = useParams();
  const listItemClasses = "flex gap-4 py-4";
  const [eventsData, setEventsData] = useState("");
  const [prizesData, setPrizesData] = useState("");
  const [globalPagesData, setGlobalPagesData] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      const getEventsRes = await getEvents();

      if (getEventsRes?.success) {
        setEventsData(getEventsRes.data);
      } else {
        setEventsData({ error: true });
      }
    };
    fetchEvents();
  }, []);

  useEffect(() => {
    const fetchPrizes = async () => {
      const getPrizesRes = await getPrizes();
      if (getPrizesRes?.success) {
        setPrizesData(getPrizesRes.data);
      } else {
        setPrizesData({ error: true });
      }
    };
    fetchPrizes();
  }, []);

  useEffect(() => {
    const fetchGlobalPages = async () => {
      const getGlobalPagesRes = await getGlobalPages();
      if (getGlobalPagesRes?.success) {
        setGlobalPagesData(getGlobalPagesRes.data);
      } else {
        setGlobalPagesData({ error: true });
      }
    };
    fetchGlobalPages();
  }, []);

  return (
    <AdminLayout>
      <div className="wrapper page">
        <div className="section space-y-10">
          <h1>Admin</h1>
          <ul className="divide-y divide-solid">
            <li className={listItemClasses} onClick={() => navigate(`/${marketId}/events`)}>
              <span className="text-xl w-1/3">Events</span>
              <span className="w-1/3 flex justify-center items-end">
                <span>
                  {eventsData.length ? eventsData.length : "0"} event
                  {(eventsData.length > 1 || eventsData.length === 0 || !eventsData.length) && <>s</>}
                </span>
              </span>
              <span className="w-1/3 text-right flex justify-end items-center">
                <span className="inline-block">
                  <Icons.ChevronRight size="20" />
                </span>
              </span>
            </li>

            <li className={listItemClasses} onClick={() => navigate(`/${marketId}/admin/prizes/`)}>
              <span className="text-xl w-1/3">Prizes</span>
              <span className="w-1/3 flex justify-center items-end">
                <span>
                  {prizesData.length ? prizesData.length : "0"} prize
                  {(prizesData.length > 1 || prizesData.length === 0 || !prizesData.length) && <>s</>}
                </span>
              </span>
              <span className="w-1/3 text-right flex justify-end items-center">
                <span className="inline-block">
                  <span className="inline-block">
                    <Icons.ChevronRight size="20" />
                  </span>
                </span>
              </span>
            </li>

            <li className={listItemClasses} onClick={() => navigate(`/${marketId}/admin/pages/global`)}>
              <span className="text-xl w-1/3">Global pages</span>
              <span className="w-1/3 flex justify-center items-end">
                <span>
                  {globalPagesData && globalPagesData.length > 0 ? globalPagesData.length : "0"} page
                  {(globalPagesData.length > 1 || globalPagesData.length === 0 || !globalPagesData.length) && <>s</>}
                </span>
              </span>
              <span className="w-1/3 text-right flex justify-end items-center">
                <span className="inline-block">
                  <span className="inline-block">
                    <Icons.ChevronRight size="20" />
                  </span>
                </span>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminDashboard;
