import { Link } from "react-router-dom";

const BackButton = () => {
  return (
    <Link className="button button--back" to={-1}>
      &lt; Back
    </Link>
  );
};

export default BackButton;
