import { useNavigate, useParams } from "react-router-dom";

const LogoutButton = () => {
  const navigate = useNavigate();
  const { marketId } = useParams();

  const handleLogout = () => {
    localStorage.removeItem("collectAndWinAdminCreds");
    navigate(`/${marketId}`);
  };

  return (
    <button className="button button--logout" onClick={() => handleLogout()}>
      Logout
    </button>
  );
};

export default LogoutButton;
