import axios from "axios";
import { handleApiError, handleApiResponse } from "../../../utils/apiHandlers";

const getPage = async (pageId) => {
  const url = `https://api.collect-and-win.com/api/client/pages/p-${pageId}`;

  const storedCreds = localStorage.getItem("collectAndWinAdminCreds");

  if (storedCreds) {
    const { token } = JSON.parse(storedCreds);

    try {
      const response = await axios.get(url, {
        mode: "cors",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Accept: "application/json",
          "API-KEY": "M4@fbCCG7N7gs]n@98$y888cKg[CQ][)",
          TOKEN: token,
        },
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }
};

export default getPage;
