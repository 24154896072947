import axios from "axios";
import { handleApiError, handleApiResponse } from "../../../utils/apiHandlers";

const deletePin = async (pins) => {
  const url = `https://api.collect-and-win.com/api/client/pins`;

  const storedCreds = localStorage.getItem("collectAndWinAdminCreds");

  if (storedCreds) {
    const { token } = JSON.parse(storedCreds);

    try {
      const response = await axios.delete(url, {
        data: { pins: pins.join(", ") },
        mode: "cors",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Accept: "application/json",
          "API-KEY": "M4@fbCCG7N7gs]n@98$y888cKg[CQ][)",
          TOKEN: token,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }
};

export default deletePin;
