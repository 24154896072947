import clsx from "clsx";
import AdminLayout from "../../Layout/AdminLayout";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import WYSIWYG from "../../partials/WYSIWYG";
import FormFeedback from "../../partials/formFeedback";
import createPage from "../../api/admin/pages/createPage";
import * as Icons from "../../partials/Icons";
import useUserLoggedIn from "../../hooks/useUserLoggedIn";

const GlobalPageAdd = () => {
  const navigate = useNavigate();
  useUserLoggedIn();

  const [bodyString, setBodyString] = useState(null);
  function handleOutput(data) {
    setBodyString(data);
  }

  const { eventId } = useParams();
  const [formFeedback, setFormFeedback] = useState(false);
  const [formFeedbackList, setFormFeedbackList] = useState(false);
  const [showAllPages, setShowAllPages] = useState(false);
  const [showPageEditor, setShowPageEditor] = useState(true);
  const [formData, setFormData] = useState({
    event_id: eventId,
    identity: "",
    title: "",
    nav_title: "",
    intro: "",
    body: "",
    button_link: "",
    button_label: "",
    body_align: "left",
    published: false,
    is_global: true,
  });

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };
  const handleCreatePage = async () => {
    const createPageRes = await createPage({
      ...Object.keys(formData)
        .filter((key) => formData[key] !== "" && formData[key] !== undefined && formData[key] !== null)
        .reduce((acc, key) => ({ ...acc, [key]: formData[key] }), {}),
      ...(bodyString ? { body: bodyString } : {}),
    });

    if (createPageRes.success) {
      navigate(-1);
    } else {
      // Handle error
      console.error("Error updating page:", createPageRes.error);
      setFormFeedback(createPageRes.error);
      setFormFeedbackList(createPageRes.errorData ? createPageRes.errorData : null);
    }
  };
  return (
    <AdminLayout>
      <div>
        <div className="section space-y-10">
          <div className="wrapper wrapper-md">
            <header className="space-y-5">
              <button className="font-bold" onClick={() => navigate(-1)}>
                &lt; Back
              </button>
              <div className="flex justify-between items-center">
                <h1>Add Page</h1>
                <div className="flex gap-4">
                  <span className="button" onClick={() => navigate(-1)}>
                    Cancel
                  </span>
                  {showPageEditor && (
                    <span className="button bg-sky-600 text-white" onClick={handleCreatePage}>
                      Save
                    </span>
                  )}
                </div>
              </div>
            </header>
          </div>
          {showPageEditor && (
            <div className="wrapper wrapper-md">
              <div className="space-y-4">
                {formFeedback && <FormFeedback feedback={formFeedback} feedbackList={formFeedbackList} />}
                <div className="flex items-center gap-4">
                  <label className="font-bold w-1/3">Title</label>
                  <input className="w-2/3" type="text" name="title" value={formData.title} onChange={handleChange} />
                </div>
                <div className="flex items-center gap-4">
                  <label className="font-bold w-1/3">Navigation Title</label>
                  <input
                    className="w-2/3"
                    type="text"
                    name="nav_title"
                    value={formData.nav_title}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex gap-4">
                  <label className="font-bold w-1/3">Intro</label>
                  <textarea
                    className="w-2/3"
                    rows="2"
                    type="text"
                    name="intro"
                    value={formData.intro}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex gap-4">
                  <label className="font-bold w-1/3">Body</label>
                  <div className="w-2/3">
                    <WYSIWYG content="" output={handleOutput} />
                  </div>
                </div>
                <div className="flex items-center gap-4">
                  <label className="font-bold w-1/3">Body text align</label>
                  <select className="w-2/3" name="body_align" value={formData.body_align} onChange={handleChange}>
                    <option value="left">Left</option>
                    <option value="center">Center</option>
                    <option value="right">Right</option>
                  </select>
                </div>
                <div className="flex items-center gap-4">
                  <label className="font-bold w-1/3">Button label</label>
                  <input
                    className="w-2/3"
                    type="text"
                    name="button_label"
                    value={formData.button_label}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex items-center gap-4">
                  <label className="font-bold w-1/3">Button link</label>
                  <input
                    className="w-2/3"
                    type="text"
                    name="button_link"
                    value={formData.button_link}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          )}
          {/* TODO - show a list of existing pages and use them as a template for a new page */}
          {/* only show x amount of pages initially and a show more button to show the rest of the pages */}
          {!showPageEditor && (
            <div className="wrapper wrapper-md">
              <div className="space-y-4">
                <div
                  className="button flex items-center justify-between w-full"
                  onClick={() => setShowPageEditor(true)}
                >
                  <span>Event name</span>
                  <span className="inline-block">
                    <span className="inline-block">
                      <Icons.ChevronRight size="20" />
                    </span>
                  </span>
                </div>
                <p>
                  <button className="underline underline-offset-4" onClick={() => setShowAllPages(!showAllPages)}>
                    Show all
                  </button>
                </p>
                <div
                  className={clsx(
                    "overflow-hidden transition-[max-height]",
                    showAllPages ? "max-h-[1000px]" : "max-h-0"
                  )}
                >
                  <div className="space-y-4 ">
                    <div
                      className="button flex items-center justify-between w-full"
                      onClick={() => setShowPageEditor(true)}
                    >
                      <span>Event name</span>
                      <span className="inline-block">
                        <span className="inline-block">
                          <Icons.ChevronRight size="20" />
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="space-y-4">
                  <p>Or start afresh</p>
                  <div
                    className="button flex items-center justify-between w-full"
                    onClick={() => setShowPageEditor(true)}
                  >
                    <span>New blank page</span>
                    <span className="inline-block">
                      <span className="inline-block">
                        <Icons.ChevronRight size="20" />
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </AdminLayout>
  );
};

export default GlobalPageAdd;
