import clsx from "clsx";
import AdminLayout from "../../Layout/AdminLayout";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import getPins from "../../api/admin/pins/getPins";
import createPin from "../../api/admin/pins/createPin";
import deletePin from "../../api/admin/pins/deletePin";
import get3DItems from "../../api/admin/pins/get3DItems";
import updatePin from "../../api/admin/pins/updatePin";
import ToggleButton from "../../components/ToggleButton/ToggleButton";
import FormFeedback from "../../partials/formFeedback";
import MapComp from "../Map/Map";
import useUserLoggedIn from "../../hooks/useUserLoggedIn";

const PinsEdit = () => {
  const navigate = useNavigate();
  const { eventId } = useParams();
  const [activeTab, setActiveTab] = useState("manual");
  const [pinsData, setPinsData] = useState([]);
  const [itemsData, setItemsData] = useState([]);
  const [formData, setFormData] = useState([]);
  const [pinsFeedback, setPinsFeedback] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [openPanelIndex, setOpenPanelIndex] = useState(null);
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const triggerRef = useRef(null);
  const modalDeleteId = useRef(null);
  const [deletedPins, setDeletedPins] = useState([]); // Array to track deleted pin IDs
  const [deletedTempPins, setDeletedTempPins] = useState([]); // Array to track deleted pin IDs

  useUserLoggedIn();

  const getActiveMode = () => {
    if (activeTab === "manual") {
      return "addPin";
    } else if (activeTab === "radius") {
      return "showBoundingCircle";
    } else {
      return "areaDrawing";
    }
  };

  const validateFormData = (data) => {
    const errors = data
      .map((pin) => {
        const pinErrors = [];
        if (!pin.title) pinErrors.push("Title is required.");
        if (!pin.lat) pinErrors.push("Latitude is required.");
        if (!pin.lon) pinErrors.push("Longitude is required.");
        if (!pin.item_id) pinErrors.push("Item is required.");
        if (!pin.pin_colour) pinErrors.push("Pin colour is required.");
        return { identity: pin.identity, errors: pinErrors };
      })
      .filter((pin) => pin.errors.length > 0);

    return errors;
  };

  const handleSaveAll = async () => {
    setPinsFeedback([]);

    // Handle deleting pins
    if (deletedPins.length > 0) {
      const deletePromises = deletePin(deletedPins);
      const deleteResults = await deletePromises;

      if (!deleteResults.success) {
        console.error(`Error deleting pin`, deleteResults.error);
      }
    }

    if (formData.length > 0) {
      const formdatatochecl = formData.filter((pin) => !deletedTempPins.includes(pin.identity));
      const errors = validateFormData(formdatatochecl);
      if (errors.length > 0) {
        setPinsFeedback(errors);
        return;
      }

      // Prepare pin data for creation and update
      const newPins = formData.filter((pin) => pin.creationMode && !deletedTempPins.includes(pin.identity));
      const existingPins = formData.filter((pin) => !pin.creationMode);

      let createPinRes;
      let updatePinRes;

      // Handle creating new pins
      if (newPins.length > 0) {
        const newPinData = newPins.map((pin) => ({
          title: pin.title,
          description: pin.description,
          lat: pin.lat,
          lon: pin.lon,
          item_id: pin.item_id,
          pin_colour: pin.pin_colour,
          subtitle: pin.subtitle || "default subtitle",
          live: pin.live ? 1 : 0,
        }));

        createPinRes = await createPin(eventId, newPinData);
        if (createPinRes.success) {
          setFormData(formData.filter((pin) => !pin.creationMode));
        } else {
          console.error("Error creating pins:", createPinRes.error);
        }
      }

      // Handle updating existing pins
      if (existingPins.length > 0) {
        const updatePromises = existingPins.map((pin) => {
          return {
            identity: pin.identity,
            title: pin.title,
            description: pin.description,
            lat: pin.lat,
            subtitle: "subtitle",
            lon: pin.lon,
            item_id: pin.item_id,
            pin_colour: pin.pin_colour,
            event_id: eventId,
            live: pin.live ? 1 : 0,
          };
        });

        const updateResults = await updatePin(eventId, updatePromises);
        if (!updateResults.success) {
          console.error(`Error updating pins`, updateResults.error);
        }
      }

      if ((createPinRes && createPinRes.success) || (updatePinRes && updatePinRes.success)) {
        const getPinsRes = await getPins(eventId);
        if (getPinsRes.success) {
          setPinsData(getPinsRes.data);
          setFormData(
            getPinsRes.data &&
              getPinsRes.data.length > 0 &&
              getPinsRes.data.map((pin) => ({
                ...pin,
                creationMode: false,
                lat: pin.geo_lat,
                lon: pin.geo_lon,
                item_id: pin.item_identity,
                pin_colour: pin.pin_colour || pinColors[pin.order],
              }))
          );
        } else {
          setPinsData({ error: true });
        }
      }
    }

    setShowSuccess(true);
    navigate(-1);

    // Clear the deletedPins array after saving
    setDeletedPins([]);
  };

  useEffect(() => {
    if (eventId) {
      const fetchPins = async () => {
        const getPinsRes = await getPins(eventId);
        if (getPinsRes.success) {
          setPinsData(getPinsRes.data);
        } else {
          setPinsData({ error: true });
        }
      };
      fetchPins();
    }
  }, [eventId]);

  useEffect(() => {
    const fetchItems = async () => {
      const getItemsRes = await get3DItems();
      if (getItemsRes.success) {
        const itemsMap = getItemsRes.data.map((item) => {
          return {
            ...item,
            item_title: item.item_title.replace("\\n", " "),
          };
        });

        setItemsData(itemsMap);
      } else {
        setItemsData({ error: true });
      }
    };
    fetchItems();
  }, []);

  useEffect(() => {
    if (pinsData && pinsData.length > 0) {
      setFormData((prevFormData) => {
        // Initialize the map directly from pinsData
        const formDataMap = new Map(
          pinsData.map((pin) => [
            pin.identity,
            {
              ...pin,
              creationMode: pin.creationMode,
              lat: pin.geo_lat,
              lon: pin.geo_lon,
              item_id: pin.item_identity,
              pin_colour: pin.pin_colour || pinColors[pin.order],
            },
          ])
        );

        // Iterate through prevFormData to add/update pins if necessary
        prevFormData.forEach((pin) => {
          if (!formDataMap.has(pin.identity)) {
            formDataMap.set(pin.identity, pin);
          }
        });

        return Array.from(formDataMap.values());
      });
    }
  }, [pinsData]);

  const handleChange = (event, pinIdentity) => {
    const { name, value, type, checked } = event.target;

    // Update formData
    const updatedFormData = formData.map((pin) =>
      pin.identity === pinIdentity ? { ...pin, [name]: type === "checkbox" ? checked : value } : pin
    );

    const adjustName = () => {
      if (name === "lat") {
        return "geo_lat";
      }
      if (name === "lon") {
        return "geo_lon";
      }
      if (name === "item_id") {
        return "item_identity";
      }
      return name;
    };
    const updatedPinData = pinsData.map((pin) =>
      pin.identity === pinIdentity ? { ...pin, [adjustName(name)]: type === "checkbox" ? checked : value } : pin
    );
    setPinsData(updatedPinData);
    setFormData(updatedFormData);
  };

  const handleDeletePin = async () => {
    if (modalDeleteId.current !== null) {
      const pinIdentity = modalDeleteId.current;
      const pin = formData.find((p) => p.identity === pinIdentity);

      // Update deletedPins array
      if (pin && !pin.creationMode) {
        setDeletedPins((prev) => [...prev, pin.identity]);
      } else if (pin) {
        setDeletedTempPins((prev) => [...prev, pin.identity]);
      }

      // setFormData(formData.filter((p) => p.identity !== pinIdentity));
      // setPinsData(pinsData.filter((p) => p.identity !== pinIdentity));

      // // Remove marker from map if applicable
      // if (triggerRef.current) {
      //   triggerRef.current.removeMarkerById(pinIdentity);
      // }

      setModalDeleteOpen(false);
    }
  };

  const pinColors = [
    "#e6194B",
    "#3cb44b",
    "#ffe119",
    "#4363d8",
    "#f58231",
    "#911eb4",
    "#42d4f4",
    "#f032e6",
    "#bfef45",
    "#fabed4",
    "#469990",
    "#dcbeff",
    "#9A6324",
    "#fffac8",
    "#800000",
  ];

  const handleNumPinChange = (e) => {
    const maxVal = 10 - pinsData.length;
    let value = parseInt(e.target.value, 10);
    if (value > maxVal) {
      value = maxVal;
    }
    if (value < 0) {
      value = 0;
    }
    e.target.value = value; // This will update the input value in the UI
    triggerRef.current?.setNumPinsToGenerate(value);
  };

  return (
    <AdminLayout>
      <div>
        <div className="section space-y-10">
          <div className="wrapper space-y-10">
            <header className="space-y-5">
              <button className="font-bold" onClick={() => navigate(-1)}>
                &lt; Back
              </button>
              <div className="flex justify-between items-center">
                <h1>Edit Pins</h1>
                <div className="flex gap-8">
                  <span className="button" onClick={() => navigate(-1)}>
                    Cancel
                  </span>
                  <span className="button bg-sky-600 text-white" onClick={handleSaveAll}>
                    Save
                  </span>
                </div>
              </div>
            </header>
            <nav className="flex items-center justify-between flex-wrap gap-4">
              <ul className="rounded-lg overflow-hidden border-2 border-neutral-300 p-0.5 flex gap-0.5">
                <li>
                  <button
                    className={clsx("button", activeTab === "manual" && "bg-neutral-300")}
                    onClick={() => setActiveTab("manual")}
                  >
                    Add pins manually
                  </button>
                </li>
                <li>
                  <button
                    className={clsx("button", activeTab === "radius" && "bg-neutral-300")}
                    onClick={() => setActiveTab("radius")}
                  >
                    Add in a radius
                  </button>
                </li>
                <li>
                  <button
                    className={clsx("button", activeTab === "shape" && "bg-neutral-300")}
                    onClick={() => setActiveTab("shape")}
                  >
                    Add in a shape
                  </button>
                </li>
              </ul>
            </nav>
            <div className="grid grid-cols-12 gap-8">
              <div className="col-span-12 md:col-span-6 space-y-4">
                <figure className="aspect-video w-full rounded bg-neutral-500">
                  <MapComp
                    ref={triggerRef}
                    initialPins={pinsData}
                    readOnly={false}
                    initialActiveMode={getActiveMode()}
                    setPinsData={setPinsData}
                  />
                </figure>
                {activeTab === "manual" && (
                  <p>
                    Tap the map to drop a pin, or add a pin in your{" "}
                    <a
                      onClick={() => triggerRef.current?.createPinAtCurrentLocation()}
                      className="underline underline-offset-4"
                    >
                      current location
                    </a>
                  </p>
                )}
                {activeTab === "radius" && (
                  <>
                    <p>Search or pan to a location, then complete the options below</p>
                    <div className="flex gap-4 items-end">
                      <div className="w-4/5">
                        <label className="font-bold mb-1 block w-2/4">Number of pins</label>
                        <input
                          className="border border-black rounded py-1 px-2 w-3/4"
                          type="number"
                          min="0"
                          defaultValue={"0"}
                          max={(10 - pinsData.length).toString()}
                          placeholder="Enter amount of pins"
                          onChange={(e) => handleNumPinChange(e)}
                        />
                        <label className="font-bold mb-1 mt-2 block w-2/4">Radius(m)</label>
                        <input
                          className="border border-black rounded py-1 px-2 w-3/4"
                          type="number"
                          min="0"
                          max="1000"
                          defaultValue={"1"}
                          placeholder="Enter radius(m)"
                          onChange={(e) => triggerRef.current?.setRadius(e.target.value)}
                        />
                      </div>
                      <button
                        className={"button bg-sky-600 text-white w-1/4"}
                        onClick={() => triggerRef.current?.generatePins()}
                      >
                        Add pins
                      </button>
                    </div>
                  </>
                )}
                {activeTab === "shape" && (
                  <>
                    <p>Draw shape on map, then complete options below</p>
                    <div className="flex gap-4 items-end">
                      <div className="w-4/5">
                        <label className="font-bold mb-1 block w-2/4">Number of pins</label>
                        <div className="flex flex-row gap-4">
                          <input
                            className="border border-black rounded py-1 px-2 w-3/4"
                            type="number"
                            min="0"
                            defaultValue={"1"}
                            max={(10 - pinsData.length).toString()}
                            placeholder="Enter amount of pins"
                            onChange={(e) => handleNumPinChange(e)}
                          />
                          <button
                            className={"button bg-sky-600 text-white w-1/4"}
                            onClick={() => triggerRef.current?.generatePolygonPins()}
                          >
                            Add pins
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="col-span-12 md:col-span-6 space-y-4">
                {showSuccess && <FormFeedback feedback="Pins successfully created/updated" />}
                {formData.length > 0 &&
                  formData.map((pin) => {
                    // Find feedback for this pin
                    const pinFeedback = pinsFeedback.find((fb) => fb.identity === pin.identity);

                    const pinDelete =
                      deletedPins.find((id) => id === pin.identity) ||
                      deletedTempPins.find((id) => id === pin.identity);

                    console.log(pinDelete);
                    return (
                      <div
                        key={pin.identity}
                        className={`rounded-md overflow-hidden border ${
                          openPanelIndex === pin.identity ? "border-gray-400" : "border-gray-200"
                        }`}
                        style={{ backgroundColor: pinDelete ? "#D3D3D3" : "#fff", opacity: pinDelete ? "0.5" : "1" }}
                      >
                        <div
                          className="p-2 flex justify-between items-center cursor-pointer"
                          style={{ backgroundColor: pinDelete ? "#D3D3D3" : "#fff" }}
                          onClick={() => setOpenPanelIndex(openPanelIndex === pin.identity ? null : pin.identity)}
                        >
                          <div className="flex items-center gap-4">
                            <div
                              className="w-4 h-4 flex items-center justify-center rounded-full"
                              style={{ backgroundColor: pin.pin_colour }}
                            />
                            <span className="font-bold text-black">{pin.title || "New Pin"}</span>
                          </div>
                          <button
                            className="button bg-red-600 text-white"
                            onClick={(e) => {
                              if (pinDelete) {
                                e.stopPropagation();
                                setDeletedPins(deletedPins.filter((id) => id !== pin.identity));
                                setDeletedTempPins(deletedTempPins.filter((id) => id !== pin.identity));
                              } else {
                                e.stopPropagation();
                                modalDeleteId.current = pin.identity;
                                setModalDeleteOpen(true);
                              }
                            }}
                          >
                            {pinDelete ? "Undo" : "Delete"}
                          </button>
                        </div>
                        <div className="p-4 space-y-4">
                          {pinFeedback && <FormFeedback feedbackList={{ errors: pinFeedback.errors }} />}
                          <div className="w-full flex items-center space-between">
                            <label className="font-bold mb-1 block w-1/4">Title</label>
                            <input
                              className="border border-black rounded py-1 px-2 w-full"
                              type="text"
                              placeholder="Title"
                              name="title"
                              value={pin.title || ""}
                              onChange={(e) => handleChange(e, pin.identity)}
                              disabled={pinDelete ? true : false}
                            />
                          </div>
                          <div className="w-full flex items-center space-between">
                            <label className="font-bold mb-1 block w-1/4">Description</label>
                            <input
                              className="border border-black rounded py-1 px-2 w-full"
                              type="text"
                              placeholder="Description"
                              name="description"
                              value={pin.description || ""}
                              onChange={(e) => handleChange(e, pin.identity)}
                              disabled={pinDelete ? true : false}
                            />
                          </div>
                          <div className="w-full flex items-center space-between">
                            <label className="font-bold mb-1 block w-1/4">Latitude</label>
                            <input
                              className="border border-black rounded py-1 px-2 w-full"
                              type="text"
                              placeholder="Latitude"
                              name="lat"
                              value={pin.lat || ""}
                              onChange={(e) => handleChange(e, pin.identity)}
                              disabled={pinDelete ? true : false}
                            />
                          </div>
                          <div className="w-full flex items-center space-between">
                            <label className="font-bold mb-1 block w-1/4">Longitude</label>
                            <input
                              className="border border-black rounded py-1 px-2 w-full"
                              type="text"
                              placeholder="Longitude"
                              name="lon"
                              value={pin.lon || ""}
                              onChange={(e) => handleChange(e, pin.identity)}
                              disabled={pinDelete ? true : false}
                            />
                          </div>
                          <div className="w-full flex items-center space-between">
                            <label className="font-bold mb-1 block w-1/4">Item</label>
                            <select
                              className="border border-black rounded py-1 px-2 w-full"
                              name="item_id"
                              value={pin.item_id || ""}
                              onChange={(e) => handleChange(e, pin.identity)}
                              disabled={pinDelete ? true : false}
                            >
                              <option value="">Select Item</option>
                              {itemsData.map((item) => (
                                <option key={item.item_identity} value={item.item_identity}>
                                  {item.item_title.replace(/(\r\n|\n|\r)/gm, " ")}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="w-full flex items-center">
                            <label className="font-bold mb-1 block w-1/4">Live</label>
                            <ToggleButton
                              name="live"
                              checked={pin.live || false}
                              onChange={(e) => handleChange(e, pin.identity)}
                              disabled={pinDelete ? true : false}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <aside
        className={clsx(
          "w-full h-full top-0 left-0 fixed bg-black/80 flex items-center justify-center transition-opacity z-10",
          modalDeleteOpen ? "opacity-1" : "pointer-events-none opacity-0"
        )}
      >
        <div className="bg-white shadow p-10 space-y-4 max-w-2xl rounded">
          <div>Are you sure you want to delete this pin?</div>
          {/* {modalDeleteId.current !== null && (
            <h2>{formData.find((pin) => pin.identity === modalDeleteId.current)?.title}</h2>
          )} */}
          <p>It will no longer be visible to users playing at this event.</p>
          <div className="flex gap-4">
            <span className="button" onClick={() => setModalDeleteOpen(false)}>
              Cancel
            </span>
            <span className="button bg-red-600 text-white" onClick={handleDeletePin}>
              Delete
            </span>
          </div>
        </div>
      </aside>
    </AdminLayout>
  );
};

export default PinsEdit;
