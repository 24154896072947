import { Link, useParams } from "react-router-dom";
import useUserLoggedIn from "../../hooks/useUserLoggedIn";
import { useEffect, useState } from "react";
import axios from "axios";
import QRCode from "react-qr-code";
import LogoutButton from "../../components/LogoutButton/LogoutButton";
import style from "./Generate.module.scss";

const Generate = () => {
  const { marketId } = useParams();
  const [gameId, setGameId] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  useUserLoggedIn();

  const gameUrl = "https://collect-and-win.com/";

  useEffect(() => {
    const storedCreds = localStorage.getItem("collectAndWinAdminCreds");

    const { eventId, token } = JSON.parse(storedCreds);

    const apiUrl = `https://api.collect-and-win.com/api/ambassador/game/generate`;

    const generateGame = async () => {
      axios
        .post(
          apiUrl,
          { event_id: eventId, market_id: marketId },
          {
            mode: "cors",
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              Accept: "application/json",
              "API-KEY": "M4@fbCCG7N7gs]n@98$y888cKg[CQ][)",
              TOKEN: token,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          if (response.data.status === "ok" && response.data.code === 200) {
            setGameId(response.data.data.game);
          } else {
            handleErrorResponse(response.data);
          }
        })
        .catch((error) => {
          console.error(error);
          handleErrorResponse(error.response.data);
        });
    };

    generateGame();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleErrorResponse = (data) => {
    if (data.status === "error") {
      if (data.code === 422 && data.messages?.responseMessage) {
        setErrorMessage(data.messages.responseMessage);
      } else {
        setErrorMessage("Sorry, there was a problem generating a game code.");
      }
    } else if (data.message) {
      setErrorMessage(data.message);
    }
  };

  return (
    <div className="page-center">
      <LogoutButton />
      <div className="text-center">
        {gameId && (
          <>
            <QRCode className={style.code} value={`${gameUrl}${gameId}`} />
            <h1 className="mt-25">{gameId}</h1>
            <h6 className="mt-10">
              {gameUrl}
              {gameId}
            </h6>
          </>
        )}

        {errorMessage && (
          <div className="mt-10" style={{ color: "red" }}>
            {errorMessage}
          </div>
        )}

        <Link className="button button--inline mt-30" to={-1}>
          Close
        </Link>
      </div>
    </div>
  );
};

export default Generate;
