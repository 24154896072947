import axios from "axios";
import { handleApiError, handleApiResponse } from "../../../utils/apiHandlers";

const getStatsByEvent = async (marketId, eventId) => {
  const url = `https://api.collect-and-win.com/api/stats/m-${marketId}/e-${eventId}`;

  try {
    const response = await axios.get(url, {
      mode: "cors",
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Accept: "application/json",
        "API-KEY": "M4@fbCCG7N7gs]n@98$y888cKg[CQ][)",
      },
    });
    return handleApiResponse(response);
  } catch (error) {
    return handleApiError(error);
  }
};

export default getStatsByEvent;
