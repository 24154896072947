import { useNavigate, useParams } from "react-router-dom";

const AdminLayout = ({ children }) => {
  const { marketId } = useParams();
  const navigate = useNavigate();

  const prettifyString = (str) => {
    return str
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleLogout = () => {
    localStorage.removeItem("collectAndWinAdminCreds");
    navigate(`/${marketId}`);
  };
  return (
    <div>
      <header className="wrapper wrapper-full py-4 bg-white shadow-md flex justify-between">
        <p>
          <span className="font-bold">Admin</span>&nbsp;|&nbsp;<span>{prettifyString(marketId)}</span>
        </p>
        <button onClick={() => handleLogout()}>Logout</button>
      </header>
      {children}
    </div>
  );
};

export default AdminLayout;
