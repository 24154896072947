import clsx from "clsx";
import AdminLayout from "../../Layout/AdminLayout";
import useUserLoggedIn from "../../hooks/useUserLoggedIn";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { formatDate } from "../../utils/formatDate";
import getEvents from "../../api/admin/events/getEvents";
import getPins from "../../api/admin/pins/getPins";
import * as Icons from "../../partials/Icons";

const EventList = () => {
  useUserLoggedIn();
  const navigate = useNavigate();
  const { marketId } = useParams();
  const [eventsData, setEventsData] = useState("");

  useEffect(() => {
    const fetchEvents = async () => {
      const getEventsRes = await getEvents();
      if (getEventsRes.success) {
        setEventsData(getEventsRes.data);
      } else {
        setEventsData({ error: true });
      }
    };
    fetchEvents();
  }, []);

  return (
    <AdminLayout>
      <div className="wrapper page">
        <div className="section space-y-10">
          <header className="space-y-5">
            <button className="font-bold" onClick={() => navigate(`/${marketId}/admin/dashboard`)}>
              &lt; Admin
            </button>
            <div className="flex justify-between items-center">
              <h1>Events</h1>
              <Link className="button bg-sky-600 text-white" to={`/${marketId}/events/add`}>
                Add an event
              </Link>
            </div>
          </header>
          {eventsData && eventsData.length > 0 && (
            <ul className="divide-y divide-solid">
              {eventsData.map((event, key) => {
                return <Event key={key} {...event} />;
              })}
            </ul>
          )}
        </div>
      </div>
    </AdminLayout>
  );
};

const Event = ({ identity, title, start_date, end_date, live }) => {
  const [pinsData, setPinsData] = useState("");

  useEffect(() => {
    const fetchPins = async () => {
      const getPinsRes = await getPins(identity);
      if (getPinsRes.success) {
        setPinsData(getPinsRes.data);
      } else {
        setPinsData({ error: true });
      }
    };
    fetchPins();
  }, [identity]);

  const navigate = useNavigate();
  const { marketId } = useParams();
  return (
    <li className="text-xs md:text-xl flex gap-4 py-4" onClick={() => navigate(`/${marketId}/events/${identity}`)}>
      {title && <span className="text-s md:text-xl w-1/5">{title}</span>}
      {start_date && end_date && (
        <span className="w-1/5 flex justify-center items-end">
          <span>
            {formatDate(start_date)} - {formatDate(end_date)}
          </span>
        </span>
      )}
      <span className="w-1/5 flex justify-center items-end">
        <span>
          {pinsData.length ? pinsData.length : "0"} pin
          {(!pinsData.length || pinsData.length === 0 || pinsData.length > 0) && <>s</>}
        </span>
      </span>
      <span className="w-1/5 flex justify-center items-end">
        <span className={clsx(live === 1 ? "text-green-600" : "text-red-600")}>
          {live === 1 ? "Enabled" : "Disabled"}
        </span>
      </span>

      <span className="hidden md:block w-1/5 text-right flex justify-end items-center">
        <span className="inline-block">
          <span className="inline-block">
            <Icons.ChevronRight size="20" />
          </span>
        </span>
      </span>
    </li>
  );
};

export default EventList;
