const Home = () => {
  return (
    <div className="page-center ">
      <div className="text-center">
        <h1>Oops!</h1>
        <div className="mt-20">
          <p>Sorry, the page could not be found</p>
        </div>
      </div>
    </div>
  );
};

export default Home;
