const formatDate = (input, includeDay = false) => {
  if (input) {
    const temp = new Date(input);
    const day = temp.getUTCDate();
    const month = temp.toLocaleString("default", { month: "long" });
    const year = temp.getUTCFullYear().toString().slice(-2);

    if (includeDay) {
      const dayOfWeek = temp.toLocaleString("default", { weekday: "short" });
      return `${dayOfWeek} ${day} ${month} ${year}`;
    } else {
      return `${day} ${month} ${year}`;
    }
  } else {
    return null;
  }
};

export { formatDate };
