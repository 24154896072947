import clsx from "clsx";
import * as Icons from "./Icons";
import { useState, useEffect } from "react";

const FormFeedback = ({ children, feedback, feedbackList, className }) => {
  const [feedbackListArray, setFeedbackListArray] = useState([]);
  const paddingClasses = "px-3 py-2";
  let typeMessageClasses = "bg-red-600";
  let type = "success";
  if (feedbackList?.errors) {
    type = "error";
  }
  if (type && type === "success") {
    typeMessageClasses = "bg-green-600";
  }
  let typeListClasses = "bg-red-100 text-red-800";
  if (type && type === "success") {
    typeListClasses = "bg-green-100 text-green-800";
  }

  useEffect(() => {
    if (feedbackList?.errors) {
      const errors = Object.values(feedbackList.errors).flat();
      setFeedbackListArray(errors);
    }
  }, [feedbackList]);

  return (
    <div className={clsx("rounded overflow-hidden animate-fade-in", className && className)}>
      <div
        className={clsx("flex gap-2 text-white items-center", paddingClasses, typeMessageClasses && typeMessageClasses)}
      >
        {type === "error" && <Icons.Error />}
        {type === "success" && <Icons.Success />}
        {children && children}
        {feedback && feedback}
      </div>
      {feedbackListArray?.length > 0 && (
        <div className={clsx(paddingClasses, typeListClasses && typeListClasses)}>
          <ul className={clsx("ul text-sm space-y-1.5 my-1.5")}>
            {feedbackListArray.map((item, key) => (
              <li key={key}>{item}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default FormFeedback;
