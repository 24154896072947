import axios from "axios";
import { handleApiError, handleApiResponse } from "../../../utils/apiHandlers";

const sortPages = async (event_id, pages) => {
  const url = `https://api.collect-and-win.com/api/client/pages/sort/`;
  const storedCreds = localStorage.getItem("collectAndWinAdminCreds");

  if (storedCreds) {
    const { token } = JSON.parse(storedCreds);

    try {
      const response = await axios.post(
        url,
        { event_id, pages },
        {
          mode: "cors",
          headers: {
            "Content-Type": "multipart/form-data",
            "X-Requested-With": "XMLHttpRequest",
            Accept: "application/json",
            "API-KEY": "M4@fbCCG7N7gs]n@98$y888cKg[CQ][)",
            TOKEN: token,
          },
        }
      );
      return handleApiResponse(response);
    } catch (error) {
      return handleApiError(error);
    }
  }
};

export default sortPages;
