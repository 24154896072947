import { useNavigate, useParams } from "react-router-dom";

const LoginSelect = () => {
  const navigate = useNavigate();
  const { marketId } = useParams();
  return (
    <div className="page-center">
      <div className="text-center space-y-6">
        <h1>Collect &amp; Win</h1>
        <p>
          <button className="button" onClick={() => navigate(`/${marketId}/ambassador/login`)}>
            Ambassador login
          </button>
        </p>
        <p>
          <button className="button" onClick={() => navigate(`/${marketId}/admin/login`)}>
            Admin
          </button>
        </p>
      </div>
    </div>
  );
};

export default LoginSelect;
