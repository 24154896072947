import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const Login = () => {
  const { marketId } = useParams(); // Extract marketId from the URL
  const [events, setEvents] = useState([]);
  const [selectedEventData, setSelectedEventData] = useState("");
  const [selectedEventId, setSelectedEventId] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessageMessage] = useState();
  const navigate = useNavigate();

  const login = async (eventId, password) => {
    const url = `https://api.collect-and-win.com/api/ambassador/login/m-${marketId}/e-${eventId}`;
    try {
      const response = await axios.post(
        url,
        { password },
        {
          mode: "cors",
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Accept: "application/json",
            "API-KEY": "M4@fbCCG7N7gs]n@98$y888cKg[CQ][)",
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.status === "ok") {
        // Create login credentials object
        const collectAndWinAdminCreds = {
          marketId,
          isloggedin: true,
          dateloggedin: new Date().toISOString(),
          eventId: selectedEventId,
          eventData: selectedEventData,
          token: response.data.data.token,
          type: "ambassador",
        };

        // Store login credentials in local storage
        localStorage.setItem("collectAndWinAdminCreds", JSON.stringify(collectAndWinAdminCreds));

        // Optionally navigate to another page, e.g., dashboard
        navigate(`/${marketId}/ambassador/dashboard`);
      } else {
        handleErrorResponse(response.data);
      }
    } catch (error) {
      console.error(error);
      handleErrorResponse(error.response.data);
    }
  };

  const handleErrorResponse = (data) => {
    if (data.status === "error" && data.messages?.responseMessage) {
      setErrorMessageMessage(data.messages.responseMessage);
    } else {
      setErrorMessageMessage("Sorry, there was a problem logging you in.");
    }
  };

  useEffect(() => {
    if (selectedEventId) {
      const selectedEvent = events.find((event) => event.identity === selectedEventId);
      setSelectedEventData(selectedEvent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEventId]);

  useEffect(() => {
    const checkLoginCredentials = () => {
      const storedCreds = localStorage.getItem("collectAndWinAdminCreds");
      if (storedCreds && storedCreds.type === "ambassador") {
        const { marketId: storedMarketId, isloggedin, dateloggedin, token } = JSON.parse(storedCreds);
        const dateNow = new Date();
        const dateLoggedIn = new Date(dateloggedin);
        const timeDifference = (dateNow - dateLoggedIn) / (1000 * 60 * 60); // Time difference in hours

        if (isloggedin && token && storedMarketId === marketId) {
          if (timeDifference < 34) {
            // Update dateloggedin and navigate to the dashboard
            const updatedCreds = {
              ...JSON.parse(storedCreds),
              dateloggedin: new Date().toISOString(),
            };
            localStorage.setItem("collectAndWinAdminCreds", JSON.stringify(updatedCreds));
            navigate(`/${marketId}/ambassador/dashboard`);
          } else {
            // Clear local storage if credentials are more than 34 hours old
            localStorage.removeItem("collectAndWinAdminCreds");
          }
        }
      }
    };

    checkLoginCredentials();
  }, [marketId, navigate]);

  useEffect(() => {
    const url = `https://api.collect-and-win.com/api/ambassador/events/m-${marketId}`;
    const fetchEvents = async () => {
      try {
        const response = await axios.get(url, {
          mode: "cors",
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Accept: "application/json",
            "API-KEY": "M4@fbCCG7N7gs]n@98$y888cKg[CQ][)",
          },
        });

        if (response.data.status === "ok") {
          setEvents(response.data.data); // Assuming the response structure contains an events array
        } else {
          handleErrorResponse(response.data);
        }
      } catch (error) {
        console.error(error);
        handleErrorResponse(error.response.data);
      }
    };

    fetchEvents();
  }, [marketId]); // Depend on marketId so it re-fetches if the marketId changes

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedEventId !== "" && password !== "") {
      login(selectedEventId, password);
    }
  };

  return (
    <div className="page-center">
      <form className="text-center space-y-4" onSubmit={handleSubmit}>
        <h1>Ambassador Login</h1>
        {errorMessage && <div className="mt-20">{errorMessage}</div>}
        {!errorMessage && events.length === 0 && <div className="mt-20">Loading events...</div>}
        {!errorMessage && events.length > 0 && (
          <>
            <select onChange={(e) => setSelectedEventId(e.target.value)} value={selectedEventId}>
              <option value="">Select an event</option>
              {events
                .sort((a, b) => a.title.localeCompare(b.title))
                .map((event) => (
                  <option key={event.identity} value={event.identity}>
                    {event.title}
                  </option>
                ))}
            </select>
            <input
              type="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className="mt-30">
              <button className="button bg-sky-600 text-white" type="submit">
                Login
              </button>
            </div>
          </>
        )}
      </form>
    </div>
  );
};

export default Login;
