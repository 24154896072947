import React, { useState, useEffect, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useUserLoggedIn from "../../hooks/useUserLoggedIn";
import QrScanner from "react-qr-scanner";
import axios from "axios";
import LogoutButton from "../../components/LogoutButton/LogoutButton";
import BackButton from "../../components/BackButton/BackButton";
import style from "./Verify.module.scss";

const Verify = () => {
  const { marketId } = useParams();
  const navigate = useNavigate();
  useUserLoggedIn();

  const [qrCode, setQrCode] = useState("");
  const [manualInput, setManualInput] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleCodeSubmit = (code) => {
    if (code && code.trim().length > 1) {
      const storedCreds = localStorage.getItem("collectAndWinAdminCreds");
      const { token } = JSON.parse(storedCreds);
      const url = `https://api.collect-and-win.com/api/ambassador/prize/verify`;

      axios
        .post(
          url,
          { prize_id: code.trim() },
          {
            mode: "cors",
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              Accept: "application/json",
              "API-KEY": "M4@fbCCG7N7gs]n@98$y888cKg[CQ][)",
              TOKEN: token,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          if (response.data.status === "ok" && response.data.code === 200) {
            localStorage.setItem("collectAndWinPrizeData", JSON.stringify(response.data.data));
            navigate(`/${marketId}/ambassador/claim`);
          } else {
            handleErrorResponse(response.data);
          }
        })
        .catch((error) => {
          console.error(error);
          handleErrorResponse(error.response.data);
        });
    }
  };

  const handleErrorResponse = (data) => {
    if (data.status === "error") {
      const errorCodes = [422, 404, 400];
      if (errorCodes.includes(data.code) && data.messages?.responseMessage) {
        setErrorMessage(data.messages.responseMessage);
      } else {
        setErrorMessage("Sorry, the prize code could not be validated.");
      }
    } else if (data.message) {
      setErrorMessage(data.message);
    }
  };

  const handleQrScan = (data) => {
    if (data && data.text && data.text.length > 0) {
      setQrCode(data.text);
    }
  };

  const handleQrError = (err) => {
    console.error(err);
  };

  const handleManualInputChange = (event) => {
    setManualInput(event.target.value);
  };

  const handleManualSubmit = () => {
    handleCodeSubmit(manualInput);
  };

  useEffect(() => {
    if (qrCode) {
      // setManualInput(qrCode);
      handleCodeSubmit(qrCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qrCode]);

  const previewStyle = {
    height: 240,
    width: 320,
    objectFit: "cover",
  };

  const memoizedQrScanner = useMemo(
    () => (
      <QrScanner
        delay={300}
        style={previewStyle}
        onError={handleQrError}
        onScan={handleQrScan}
        constraints={{
          video: { facingMode: "environment" },
        }}
      />
    ),
    []
  );

  return (
    <div className="page-center">
      <BackButton />
      <LogoutButton />
      <div className="text-cente space-y-4 text-center">
        <h1>Verify Prize Code</h1>

        <div className={style.scanner} style={previewStyle}>
          {memoizedQrScanner}
        </div>
        {/* {qrCode && <p>QR Code Value: {qrCode}</p>} */}

        <h6>Scan a prize QR code, or enter it manually below</h6>
        <input type="text" value={manualInput} onChange={handleManualInputChange} placeholder="Prize Code" />

        {errorMessage && (
          <div className="mt-10" style={{ color: "red" }}>
            {errorMessage}
          </div>
        )}

        <button className="button bg-sky-600 text-white mt-30" onClick={handleManualSubmit}>
          Verify Code
        </button>
        {/* {manualInput && <p>Manual Input Value: {manualInput}</p>} */}
      </div>
    </div>
  );
};

export default Verify;
