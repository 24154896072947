import clsx from "clsx";
import useUserLoggedIn from "../../hooks/useUserLoggedIn";
import AdminLayout from "../../Layout/AdminLayout";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import updatePrizeConfig from "../../api/admin/prizes/updatePrizeConfig";
import getPrizes from "../../api/admin/prizes/getPrizes";
import getPrizeConfig from "../../api/admin/prizes/getPrizeConfig";
import ToggleButton from "../../components/ToggleButton/ToggleButton";
import FormFeedback from "../../partials/formFeedback";
import deletePrizeConfig from "../../api/admin/prizes/deletePrizeConfig";

const PrizeEdit = () => {
  useUserLoggedIn();

  const navigate = useNavigate();
  const { eventId, prizeId } = useParams();
  const [percentageValue, setPercentageValue] = useState("1");
  const [selectedPrizeId, setSelectedPrizeId] = useState("");
  const [formFeedback, setFormFeedback] = useState(false);
  const [formFeedbackList, setFormFeedbackList] = useState(false);
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [prizesData, setPrizesData] = useState("");
  const [formData, setFormData] = useState({
    live: "",
    identity: "",
    title: "",
    configs: [
      {
        event_id: eventId,
        prize_id: "",
        identity: prizeId,
        live: false,
        identity: "",
        prize_mechanic: "",
        prize_delivery: "",
        prize_type: "",
        chance_value: "50",
        frequency_period: "",
        frequency: "",
      },
    ],
  });

  useEffect(() => {
    const fetchGlobalPrizeData = async () => {
      const getPrizesRes = await getPrizes();
      if (getPrizesRes.success) {
        setPrizesData(getPrizesRes.data);
      } else {
        setPrizesData({ error: true });
      }
    };
    fetchGlobalPrizeData();
  }, []);

  useEffect(() => {
    setFormData({
      ...formData,
      chance_value: percentageValue,
    });
  }, [percentageValue]);

  useEffect(() => {
    setFormData({
      ...formData,
      prize_id: selectedPrizeId,
    });
  }, [selectedPrizeId]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await getPrizeConfig(prizeId); // Fetch the event data
      if (res.success) {
        const prizeConfig = res.data;

        setPercentageValue(prizeConfig.configs[0].chance_value);
        setFormData({
          live: prizeConfig.live === 1 ? true : false,
          identity: prizeConfig.identity,
          title: prizeConfig.title,
          configs: [
            {
              event_id: eventId,
              prize_id: prizeConfig.identity,
              identity: prizeId,
              live: prizeConfig.configs[0].live === 1 ? true : false,
              identity: prizeConfig.configs[0].identity,
              prize_mechanic: prizeConfig.configs[0].prize_mechanic,
              prize_delivery: prizeConfig.configs[0].prize_delivery,
              prize_type: prizeConfig.configs[0].prize_type,
              chance_value: prizeConfig.configs[0].chance_value,
              frequency_period: prizeConfig.configs[0].frequency_period,
              frequency: prizeConfig.configs[0].frequency,
            },
          ],
        });
      } else {
        console.error("Error fetching event data:", res.error);
      }
    };

    fetchData();
  }, [prizeId]);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    setFormData({
      // configs: [
      // {
      ...formData,
      // live: formData.live === true ? 1 : 0,
      // prize_id: formData.prize_id,
      // prize_mechanic: formData.prize_mechanic,
      // prize_delivery: formData.prize_delivery,
      // prize_type: formData.prize_delivery,
      // chance_value: formData.chance_value,
      // frequency_period: formData.frequency_period,
      // frequency: formData.frequency,
      [name]: type === "checkbox" ? checked : value,
      // },
      //   ],
    });
  };

  const handleDeletePrize = async () => {
    const deleteEventRes = await deletePrizeConfig(prizeId);
    if (deleteEventRes.success) {
      navigate(-1);
    }
  };

  const handleUpdateConfig = async () => {
    const data = {
      chance_value: percentageValue,
      live: formData.configs[0].live ? 1 : 0,
      event_id: eventId,
      prize_id: formData.identity,
    };

    const createConfigRes = await updatePrizeConfig(prizeId, data);

    if (createConfigRes.success) {
      navigate(-1);
    } else {
      // Handle error
      console.error("Error creating prize config:", createConfigRes.error);
      setFormFeedback(createConfigRes.error);
      setFormFeedbackList(createConfigRes.errorData ? createConfigRes.errorData : null);
    }
  };

  const handleToggleChange = (isChecked) => {
    setFormData((prevState) => ({
      ...prevState,
      configs: [{ live: isChecked }],
    }));
  };
  if (prizesData) {
    return (
      <AdminLayout>
        <div>
          <div className="section space-y-10">
            <div className="wrapper wrapper-md">
              <header className="space-y-5">
                <button className="font-bold" onClick={() => navigate(-1)}>
                  &lt; Back
                </button>
                <div className="flex justify-between items-center">
                  <h1>Edit Prize</h1>
                  <div className="flex gap-4">
                    <span className="button" onClick={() => navigate(-1)}>
                      Cancel
                    </span>
                    <span className="button bg-sky-600 text-white" onClick={handleUpdateConfig}>
                      Save
                    </span>
                    <span className="button bg-red-600 text-white" onClick={() => setModalDeleteOpen(true)}>
                      Delete
                    </span>
                  </div>
                </div>
              </header>
            </div>

            <div className="wrapper wrapper-md">
              <div className="space-y-4">
                {formFeedback && <FormFeedback feedback={formFeedback} feedbackList={formFeedbackList} />}
                <div className="flex items-center gap-4">
                  <label className="font-bold w-1/3">Prize</label>
                  <select
                    className="w-2/3"
                    name="prize"
                    value={formData.configs[0].prize_id}
                    onChange={(e) => setSelectedPrizeId(e.target.value)}
                    defaultValue={formData.configs[0].prize_id}
                  >
                    <option value="" disabled>
                      Select a prize
                    </option>
                    {prizesData &&
                      prizesData.length > 0 &&
                      prizesData.map((prize) => (
                        <option key={prize.identity} value={prize.identity}>
                          {prize.title}
                        </option>
                      ))}
                  </select>
                </div>
                {/* !!!!!!!!!!!!!!!!
                DISABLED
                !!!!!!!!!!!!!!!! */}
                <div className="flex items-center gap-4 opacity-30 pointer-events-none">
                  <label className="font-bold w-1/3">Delivery</label>
                  <select
                    className="w-2/3"
                    name="delivery"
                    value={formData.configs[0].prize_delivery}
                    defaultValue={formData.configs[0].prize_delivery}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      Select a method
                    </option>
                    <option value="via_ambassador">Via Ambassador</option>
                    <option value="direct_to_user">Direct to user</option>
                  </select>
                </div>
                {/* !!!!!!!!!!!!!!!!
                DISABLED
                !!!!!!!!!!!!!!!! */}
                <div className="flex items-center gap-4 opacity-30 pointer-events-none">
                  <label className="font-bold w-1/3">Mechanic</label>
                  <select
                    className="w-2/3"
                    name="prize_mechanic"
                    value={formData.prize_mechanic}
                    onChange={handleChange}
                    defaultValue={formData.configs[0].prize_mechanic}
                  >
                    <option value="" disabled="disabled">
                      Choose an option
                    </option>
                    <option value="weighted">Weighted</option>
                    <option value="percent_chance">Percentage</option>
                    <option value="time_released">Time Released with Weighted</option>
                    <option value="time_released_percent_chance">Time Released with Percentage</option>
                  </select>
                </div>
                {/* !!!!!!!!!!!!!!!!
                DISABLED
                !!!!!!!!!!!!!!!! */}
                <div className="flex items-center gap-4 opacity-30 pointer-events-none">
                  <label className="font-bold w-1/3">Type</label>
                  <select
                    className="w-2/3"
                    name="prize_type"
                    value={formData.prize_type}
                    onChange={handleChange}
                    defaultValue={formData.configs[0].prize_type}
                  >
                    <option value="" disabled="disabled">
                      Choose an option
                    </option>
                    <option value="item_only">Physical Item Only (no code)</option>
                    <option value="redeemable_code">One-Off Redeemable Code (same code for all)</option>
                    <option value="unique_codes">Unique Redeemable Codes (from code pool)</option>
                  </select>
                </div>
                <div className="flex gap-4">
                  <label className="font-bold w-1/3">Chance of winning</label>
                  <div className="w-2/3">
                    <div className="flex gap-4 items-center">
                      <input
                        className="w-full"
                        type="range"
                        min="0"
                        max="100"
                        name="chance_value"
                        value={percentageValue}
                        onChange={(e) => setPercentageValue(e.target.value)}
                      />
                      {percentageValue && percentageValue}
                    </div>
                    <p className="text-sm">
                      Please ensure that the combined percent chance of all prizes adds up to 100.
                    </p>
                  </div>
                </div>
                {/* This may not be required, possibly was included in old wireframes */}
                {/* <div className="flex gap-4 opacity-30 pointer-events-none">
                  <label className="font-bold w-1/3">Frequency</label>
                  <div className="w-2/3">
                    <div className="flex gap-4 items-center">
                      <select
                        className="w-full"
                        name="frequency_period"
                        value={formData.frequency_period}
                        onChange={handleChange}
                      >
                        <option value="" disabled="disabled">
                          Choose an option
                        </option>{" "}
                        <option value=""></option>
                        <option value="P1D">Per Day</option>
                        <option value="P1W">Per Week</option>
                        <option value="P1M">Per Month</option>
                        <option value="P1Y">Per Year</option>
                      </select>
                    </div>
                  </div>
                </div> */}
                <div className="flex items-center gap-4">
                  <label className="font-bold w-1/3">Enabled</label>
                  <div className="w-2/3">
                    <ToggleButton
                      name="live"
                      checked={formData.configs[0].live}
                      onChange={(e) => handleToggleChange(e.target.checked)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <aside
          className={clsx(
            "w-full h-full top-0 left-0 fixed bg-black/80 flex items-center justify-center transition-opacity",
            modalDeleteOpen ? "opacity-1" : "pointer-events-none opacity-0"
          )}
        >
          <div className="bg-white shadow p-10 space-y-4 max-w-2xl rounded">
            <h2>Are you sure you want to delete this prize?</h2>
            <p>Users will no longer be able to win or claim this prize.</p>
            <div className="flex gap-4">
              <span className="button" onClick={() => setModalDeleteOpen(false)}>
                Cancel
              </span>
              <Link className="button bg-red-600 text-white" onClick={() => handleDeletePrize()}>
                Delete
              </Link>
            </div>
          </div>
        </aside>
      </AdminLayout>
    );
  }
};

export default PrizeEdit;
