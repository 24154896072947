import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef } from "react";
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import styles from "./Map.module.scss";
import clsx from "clsx";
import * as turf from "@turf/turf";

mapboxgl.accessToken = "pk.eyJ1IjoiYmVua2VydmUiLCJhIjoiY2x2b2RhaDhzMGsydTJrbzFnZjdqdG9pcSJ9.2oB_BvYllcwr3Zd_0khzHA";

const Map = forwardRef(
  (
    {
      initialActiveMode = null,
      initialMinDistance = 0,
      initialNumPins = 1,
      initialPins = [],
      readOnly = true,
      setPinsData,
    },
    ref
  ) => {
    const mapContainerRef = useRef(null);
    const mapRef = useRef(null);
    const userLocationRef = useRef(null);
    const geolocateControlRef = useRef(null);
    const pinRef = useRef(null);
    const markersRef = useRef([]);
    const polygonCounter = useRef(0);
    const draw = useRef(null);
    const numPins = useRef(initialNumPins);

    const [activeMode, setActiveMode] = useState(initialActiveMode);
    const [minDistance, setMinDistance] = useState(initialMinDistance);
    const [maxDistance, setMaxDistance] = useState(1);
    const [generatedPins, setGeneratedPins] = useState([]);

    useEffect(() => {
      setActiveMode(initialActiveMode);
      numPins.current = initialNumPins;
    }, [initialActiveMode, initialNumPins]);

    useEffect(() => {
      const map = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: "mapbox://styles/mapbox/streets-v11",
        center: [-2.3617, 51.3811],
        zoom: 11,
        pitch: 0,
        interactive: !readOnly,
        attributionControl: false,
      });
      mapRef.current = map;

      map.on("draw.create", function (e) {
        const featureId = e.features[0].id;
        disableFeatureInteraction(featureId);
      });

      function disableFeatureInteraction(featureId) {
        const feature = draw.current.get(featureId);
        feature.properties["interaction"] = false;
        draw.current.delete(featureId);
        draw.current.add(feature);
      }

      // Prevent default interactions for non-interactive features
      map.on("draw.selectionchange", function (e) {
        if (e.features.length && !e.features[0].properties["interaction"]) {
          draw.current.changeMode("simple_select", { featureIds: [] });
        }
      });

      map.on("draw.update", function (e) {
        if (e.features.length && !e.features[0].properties["interaction"]) {
          draw.current.changeMode("simple_select", { featureIds: [] });
        }
      });

      map.on("click", function (e) {
        const features = map.queryRenderedFeatures(e.point, { layers: ["gl-draw-polygon-fill-active.cold"] });
        if (features.length && features[0].properties["interaction"] === false) {
          // Prevent default behavior
          e.preventDefault();
          e.stopPropagation();
        }
      });

      const geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
        marker: false,
      });

      if (!readOnly) {
        map.addControl(geocoder);
      }

      const geolocateControl = new mapboxgl.GeolocateControl({
        positionOptions: { enableHighAccuracy: true },
        showUserHeading: true,
        trackUserLocation: true,
        fitBoundsOptions: { maxZoom: 18 },
      });

      if (!readOnly) {
        map.addControl(geolocateControl);
        geolocateControlRef.current = geolocateControl;
      }
      map.on("load", () => {
        geolocateControl.trigger();

        if (initialPins.length > 0) {
          const bounds = new mapboxgl.LngLatBounds();

          initialPins.forEach((pin) => {
            bounds.extend([pin.geo_lon, pin.geo_lat]);
          });

          mapRef.current.fitBounds(bounds, {
            padding: 40,
          });
        }
      });

      geolocateControl.once("geolocate", (e) => {
        const { longitude, latitude, heading } = e.coords;
        map.setCenter([longitude, latitude]);
        userLocationRef.current = [longitude, latitude];
        rotateMapToInitialHeading(map, heading);

        if (activeMode === "showBoundingCircle") {
          drawBoundingCircle(map, [longitude, latitude], maxDistance);
        }
      });

      geolocateControl.on("geolocate", (e) => {
        const { longitude, latitude } = e.coords;
        userLocationRef.current = [longitude, latitude];
      });

      geolocateControl.on("heading", (e) => {
        const heading = e?.target?.heading;
        if (mapRef.current && heading !== null) {
          mapRef.current.setBearing(heading);
        }
      });

      const rotateMapToInitialHeading = (map, initialHeading) => {
        const mapHeading = 360 - initialHeading;
        map.rotateTo(mapHeading, { duration: 0 });
      };

      geocoder.on("result", (e) => {
        const [longitude, latitude] = e.result.center;
        map.flyTo({
          center: [longitude, latitude],
          zoom: 15,
          speed: 2,
          curve: 1,
        });

        if (geolocateControlRef.current) {
          geolocateControlRef.current._watchState = "OFF";
        }

        if (activeMode === "addPin") {
          addPinToMap(map, [longitude, latitude]);
        }

        userLocationRef.current = [longitude, latitude];

        if (activeMode === "showBoundingCircle") {
          drawBoundingCircle(map, [longitude, latitude]);
        }
      });

      map.on("click", (e) => {
        if (activeMode === "addPin") {
          const { lng, lat } = e.lngLat;
          addPinToMap(map, [lng, lat]);
        }
      });

      return () => map.remove();
    }, [activeMode]);

    const updateArea = () => {
      const data = draw.current.getAll();
      if (data.features.length > 0) {
        const polygon = data.features[0];
        generatePinsInsidePolygon(polygon, numPins.current);
      }
    };

    useEffect(() => {
      draw.current = new MapboxDraw({
        displayControlsDefault: false,
        controls: { polygon: true, trash: true },
      });

      if (activeMode === "areaDrawing") {
        mapRef.current.addControl(draw.current);

        const polyButton = document.getElementsByClassName("mapbox-gl-draw_polygon");
        const trashButton = document.getElementsByClassName("mapbox-gl-draw_trash");

        trashButton[0].style.display = "none";

        mapRef.current.on("draw.delete", function (event) {
          polyButton[0].style.display = "block";
          trashButton[0].style.display = "none";
          // Decrement the counter when a polygon is deleted
          polygonCounter.current--;
        });
      } else if (mapRef.current.hasControl(draw.current)) {
        mapRef.current.removeControl(draw.current);
      }

      return () => {
        clearMarkers();
      };
    }, [activeMode]);

    useEffect(() => {
      clearMarkers();
      initialPins.length > 0 &&
        initialPins.forEach((pin) => {
          const marker = new mapboxgl.Marker({ color: pin.pin_colour, draggable: !readOnly })
            .setLngLat([pin.geo_lon, pin.geo_lat])
            .addTo(mapRef.current);
          markersRef.current[pin.identity] = marker;

          marker.on("dragend", () => {
            const lngLat = marker.getLngLat();
            const newPins = initialPins.map((p) => {
              return p.identity === pin.identity ? { ...p, geo_lon: lngLat.lng, geo_lat: lngLat.lat } : p;
            });

            setPinsData(newPins);
          });
        });
    }, [activeMode, initialPins, readOnly, setPinsData]);

    const clearMarkers = () => {
      Object.values(markersRef.current).forEach((marker) => marker.remove());
      markersRef.current = {};
    };

    const generateRandomPoints = (
      lat,
      lng,
      numPoints,
      minDistanceMiles,
      maxDistanceMiles,
      minSeparation = 20,
      maxRetries = 100
    ) => {
      const points = [];
      const R = 6371e3; // Radius of Earth in meters

      const haversineDistance = (lat1, lng1, lat2, lng2) => {
        const dLat = ((lat2 - lat1) * Math.PI) / 180;
        const dLng = ((lng2 - lng1) * Math.PI) / 180;
        const a =
          Math.sin(dLat / 2) ** 2 +
          Math.cos((lat1 * Math.PI) / 180) * Math.cos((lat2 * Math.PI) / 180) * Math.sin(dLng / 2) ** 2;
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return R * c;
      };

      // Convert miles to meters
      const minDistanceMeters = minDistanceMiles * 1609.34;
      const maxDistanceMeters = maxDistanceMiles * 1609.34;

      for (let i = 0; i < numPoints; i++) {
        let retries = 0;
        let pointAdded = false;
        let newLat, newLng;

        while (!pointAdded && retries < maxRetries) {
          const randomDistance = Math.random() * (maxDistanceMeters - minDistanceMeters) + minDistanceMeters;
          const randomAngle = Math.random() * 2 * Math.PI;
          const deltaLat = ((randomDistance * Math.cos(randomAngle)) / R) * (180 / Math.PI);
          const deltaLng =
            ((randomDistance * Math.sin(randomAngle)) / (R * Math.cos((lat * Math.PI) / 180))) * (180 / Math.PI);

          newLat = lat + deltaLat;
          newLng = lng + deltaLng;

          let isFarEnough = true;
          for (const point of points) {
            const distance = haversineDistance(point.lat, point.lng, newLat, newLng);
            if (distance < minSeparation) {
              isFarEnough = false;
              break;
            }
          }

          if (isFarEnough) {
            points.push({ lat: newLat, lng: newLng });
            pointAdded = true;
          } else {
            retries++;
          }
        }

        if (!pointAdded && retries === maxRetries) {
          points.push({ lat: newLat, lng: newLng });
        }
      }

      return points;
    };

    const drawBoundingCircle = (map, center, radiusInMiles) => {
      if (map.getLayer("polygon")) map.removeLayer("polygon");
      if (map.getSource("polygon")) map.removeSource("polygon");

      // Create and add the new circle source and layer
      const circleSource = createGeoJSONCircle(center, radiusInMiles);
      map.addSource("polygon", circleSource);
      map.addLayer({
        id: "polygon",
        type: "line",
        source: "polygon",
        layout: {},
        paint: { "line-color": "red", "line-width": 2 },
      });
    };

    const createGeoJSONCircle = (center, radiusInMiles, points = 64) => {
      const coords = {
        latitude: center[1],
        longitude: center[0],
      };

      // Convert miles to kilometers
      const km = radiusInMiles * 1.60934;
      const ret = [];
      const distanceX = km / (111.32 * Math.cos((coords.latitude * Math.PI) / 180));
      const distanceY = km / 110.574;

      for (let i = 0; i < points; i++) {
        const theta = (i / points) * (2 * Math.PI);
        const x = distanceX * Math.cos(theta);
        const y = distanceY * Math.sin(theta);

        ret.push([coords.longitude + x, coords.latitude + y]);
      }
      ret.push(ret[0]);

      return {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              geometry: { type: "Polygon", coordinates: [ret] },
            },
          ],
        },
      };
    };

    const generatePinsInsidePolygon = (polygon, pins) => {
      const bbox = turf.bbox(polygon);
      const points = [];

      while (points.length < pins) {
        const point = turf.randomPoint(1, { bbox }).features[0];
        if (turf.booleanPointInPolygon(point, polygon)) {
          points.push(point.geometry.coordinates);
        }
      }

      setGeneratedPins(points);
      points.forEach(([lng, lat]) => {
        addPinToMap(mapRef.current, [lng, lat]);
      });
    };

    const addPinToMap = (map, [lng, lat]) => {
      if (Object.values(markersRef.current).length === 10) {
        return;
      }
      const newColor = "#" + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, "0");
      pinRef.current = new mapboxgl.Marker({ color: newColor, draggable: !readOnly }).setLngLat([lng, lat]).addTo(map);

      const newPin = {
        identity: Date.now() + Math.random(1),
        live: 1,
        title: "New Pin",
        subtitle: "",
        description: "",
        geo_lat: lat,
        geo_lon: lng,
        pin_colour: newColor,
        creationMode: true,
        item_identity: undefined,
      };
      markersRef.current[newPin.identity] = pinRef.current;

      if (Object.values(markersRef.current).length > 0) {
        setPinsData((prevPins) => (prevPins.length > 0 ? [newPin, ...prevPins] : [newPin]));
      } else {
        setPinsData([newPin]);
      }
    };

    const handleGeneratePins = () => {
      if (userLocationRef.current && mapRef.current) {
        const [lng, lat] = userLocationRef.current;
        const pins = generateRandomPoints(lat, lng, numPins.current, minDistance, maxDistance);
        setGeneratedPins(pins);

        pins.forEach(({ lat, lng }) => {
          addPinToMap(mapRef.current, [lng, lat]);
        });
      }
    };

    const handleCreatePinAtCurrentLocation = () => {
      if (userLocationRef.current && mapRef.current) {
        // clearMarkers(); // Clear existing markers
        addPinToMap(mapRef.current, userLocationRef.current);
      }
    };

    const removeMarkerById = (id) => {
      // Check if marker with the given ID exists
      if (markersRef.current[id]) {
        const marker = markersRef.current[id];

        // Check if the marker has the remove method
        if (typeof marker.remove === "function") {
          marker.remove();

          // Verify if the marker's element is still in the DOM
          const markerElement = marker.getElement();
          if (markerElement && markerElement.parentNode) {
            console.error("Failed to remove marker from the map. Marker element still present in DOM.");
          } else {
            console.log("Marker removed:", id);
            delete markersRef.current[id];
          }
        } else {
          console.error("The marker object does not have a remove method:", marker);
        }
      } else {
        console.error("Marker with ID not found:", id);
      }
    };

    const setNumPinsToGenerate = (num) => {
      numPins.current = num;
    };

    const setRadius = (num) => {
      const newMaxDistance = Number(num);
      setMaxDistance(newMaxDistance); // Update the state

      // Directly use newMaxDistance instead of relying on state
      if (userLocationRef.current && mapRef.current) {
        drawBoundingCircle(mapRef.current, userLocationRef.current, newMaxDistance);
      }
    };

    // Expose methods to parent component
    useImperativeHandle(ref, () => ({
      generatePins: handleGeneratePins,
      createPinAtCurrentLocation: handleCreatePinAtCurrentLocation,
      removeMarkerById: removeMarkerById,
      setNumPinsToGenerate: setNumPinsToGenerate,
      setRadius: setRadius,
      generatePolygonPins: updateArea,
    }));

    return (
      <div className={clsx(styles.wrapper)}>
        <div
          ref={mapContainerRef}
          style={{ top: "0", left: "0", position: "absolute", width: "100%", height: "100%" }}
        />
      </div>
    );
  }
);

export default Map;
