import clsx from "clsx";
import useUserLoggedIn from "../../hooks/useUserLoggedIn";
import AdminLayout from "../../Layout/AdminLayout";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import createConfig from "../../api/admin/prizes/createConfig";
import getPrizes from "../../api/admin/prizes/getPrizes";
import ToggleButton from "../../components/ToggleButton/ToggleButton";
import FormFeedback from "../../partials/formFeedback";

const PrizeAdd = () => {
  useUserLoggedIn();
  const navigate = useNavigate();
  const { eventId } = useParams();
  const [percentageValue, setPercentageValue] = useState("1");
  const [selectedPrizeId, setSelectedPrizeId] = useState("");
  const [formFeedback, setFormFeedback] = useState(false);
  const [formFeedbackList, setFormFeedbackList] = useState(false);
  const [prizesData, setPrizesData] = useState("");
  const [formData, setFormData] = useState({
    // live: "",
    // identity: "",
    // title: "",
    // configs: [
    //   {
    event_id: eventId,
    prize_id: "",
    identity: "",
    live: false,
    identity: "",
    prize_mechanic: "weighted",
    prize_delivery: "via_ambassador",
    prize_type: "item_only",
    chance_value: "50",
    frequency_period: "",
    frequency: "",
    //   },
    // ],
  });

  useEffect(() => {
    const fetchPrizes = async () => {
      const getPrizesRes = await getPrizes();
      if (getPrizesRes.success) {
        setPrizesData(getPrizesRes.data);
      } else {
        setPrizesData({ error: true });
      }
    };
    fetchPrizes();
  }, []);

  useEffect(() => {
    setFormData({
      ...formData,
      chance_value: percentageValue,
    });
  }, [percentageValue]);

  useEffect(() => {
    setFormData({
      ...formData,
      prize_id: selectedPrizeId,
    });
  }, [selectedPrizeId]);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    setFormData({
      // configs: [
      // {
      ...formData,
      // live: formData.live === true ? 1 : 0,
      // prize_id: formData.prize_id,
      // prize_mechanic: formData.prize_mechanic,
      // prize_delivery: formData.prize_delivery,
      // prize_type: formData.prize_delivery,
      // chance_value: formData.chance_value,
      // frequency_period: formData.frequency_period,
      // frequency: formData.frequency,
      [name]: type === "checkbox" ? checked : value,
      // },
      //   ],
    });
  };

  const handleCreateConfig = async () => {
    const createConfigRes = await createConfig({
      ...formData,
      live: formData.live === true ? 1 : 0,
    });

    if (createConfigRes.success) {
      navigate(-1);
    } else {
      // Handle error
      console.error("Error creating prize config:", createConfigRes.error);
      setFormFeedback(createConfigRes.error);
      setFormFeedbackList(createConfigRes.errorData ? createConfigRes.errorData : null);
    }
  };

  const handleToggleChange = (isChecked) => {
    setFormData((prevState) => ({
      ...prevState,
      live: isChecked,
    }));
  };

  return (
    <AdminLayout>
      <div>
        <div className="section space-y-10">
          <div className="wrapper wrapper-md">
            <header className="space-y-5">
              <button className="font-bold" onClick={() => navigate(-1)}>
                &lt; Back
              </button>
              <div className="flex justify-between items-center">
                <h1>Create Prize</h1>
                <div className="flex gap-4">
                  <span className="button" onClick={() => navigate(-1)}>
                    Cancel
                  </span>
                  <span className="button bg-sky-600 text-white" onClick={handleCreateConfig}>
                    Save
                  </span>
                </div>
              </div>
            </header>
          </div>

          <div className="wrapper wrapper-md">
            <div className="space-y-4">
              {formFeedback && <FormFeedback feedback={formFeedback} feedbackList={formFeedbackList} />}
              <div className="flex items-center gap-4">
                <label className="font-bold w-1/3">Prize</label>
                <select
                  className="w-2/3"
                  name="prize"
                  value={formData.prize_id}
                  onChange={(e) => setSelectedPrizeId(e.target.value)}
                >
                  <option value="" disabled>
                    Select a prize
                  </option>
                  {prizesData &&
                    prizesData.map((prize) => (
                      <option key={prize.identity} value={prize.identity}>
                        {prize.title}
                      </option>
                    ))}
                </select>
              </div>
              <div className="flex items-center gap-4 opacity-30 pointer-events-none">
                <label className="font-bold w-1/3">Delivery</label>
                <select className="w-2/3" name="delivery" value={formData.prize_delivery} onChange={handleChange}>
                  <option value="" disabled>
                    Select a method
                  </option>
                  <option selected value="via_ambassador">
                    Via Ambassador
                  </option>
                  <option value="direct_to_user">Direct to user</option>
                </select>
              </div>
              <div className="flex items-center gap-4 opacity-30 pointer-events-none">
                <label className="font-bold w-1/3">Mechanic</label>
                <select className="w-2/3" name="prize_mechanic" value={formData.prize_mechanic} onChange={handleChange}>
                  <option value="" disabled="disabled">
                    Choose an option
                  </option>
                  <option value="weighted">Weighted</option>
                  <option value="percent_chance">Percentage</option>
                  <option value="time_released">Time Released with Weighted</option>
                  <option value="time_released_percent_chance">Time Released with Percentage</option>
                </select>
              </div>
              <div className="flex items-center gap-4 opacity-30 pointer-events-none">
                <label className="font-bold w-1/3">Type</label>
                <select className="w-2/3" name="prize_type" value={formData.prize_type} onChange={handleChange}>
                  <option value="" disabled="disabled">
                    Choose an option
                  </option>
                  <option value="item_only">Physical Item Only (no code)</option>
                  <option value="redeemable_code">One-Off Redeemable Code (same code for all)</option>
                  <option value="unique_codes">Unique Redeemable Codes (from code pool)</option>
                </select>
              </div>
              <div className="flex gap-4">
                <label className="font-bold w-1/3">Chance of winning</label>
                <div className="w-2/3">
                  <div className="flex gap-4 items-center">
                    <input
                      className="w-full"
                      type="range"
                      min="0"
                      max="100"
                      name="chance_value"
                      value={percentageValue}
                      onChange={(e) => setPercentageValue(e.target.value)}
                    />
                    {percentageValue && percentageValue}
                  </div>
                  <p className="text-sm">
                    Please ensure that the combined percent chance of all prizes adds up to 100.
                  </p>
                </div>
              </div>
              {/* <div className="flex gap-4">
                <label className="font-bold w-1/3">Frequency</label>
                <div className="w-2/3">
                  <div className="flex gap-4 items-center">
                    <select
                      className="w-full"
                      name="frequency_period"
                      value={formData.frequency_period}
                      onChange={handleChange}
                    >
                      <option value="" disabled="disabled">
                        Choose an option
                      </option>{" "}
                      <option value=""></option>
                      <option value="P1D">Per Day</option>
                      <option value="P1W">Per Week</option>
                      <option value="P1M">Per Month</option>
                      <option value="P1Y">Per Year</option>
                    </select>
                  </div>
                </div>
              </div> */}
              <div className="flex items-center gap-4">
                <label className="font-bold w-1/3">Enabled</label>
                <div className="w-2/3">
                  <ToggleButton
                    name="live"
                    checked={formData.live}
                    onChange={(e) => handleToggleChange(e.target.checked)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default PrizeAdd;
