import React, { useState } from "react";
import axios from "axios";
import { Link, useParams, useNavigate } from "react-router-dom";
import useUserLoggedIn from "../../hooks/useUserLoggedIn";
import LogoutButton from "../../components/LogoutButton/LogoutButton";
import clsx from "clsx";

const Claim = () => {
  const navigate = useNavigate();
  const { marketId } = useParams();
  const [errorMessage, setErrorMessage] = useState("");
  useUserLoggedIn();

  // get prize data from local storage
  const collectAndWinPrizeData = localStorage.getItem("collectAndWinPrizeData");
  if (!collectAndWinPrizeData || collectAndWinPrizeData === null) {
    navigate(`/${marketId}/ambassador/verify`);
  }
  const prizeData = JSON.parse(collectAndWinPrizeData);

  // dev
  // prizeData.claimed = false;

  const claimPrize = () => {
    const storedCreds = localStorage.getItem("collectAndWinAdminCreds");

    if (storedCreds && collectAndWinPrizeData) {
      const { token } = JSON.parse(storedCreds);
      const { code } = JSON.parse(collectAndWinPrizeData);

      const url = `https://api.collect-and-win.com/api/ambassador/prize/claim`;

      axios
        .post(
          url,
          { prize_id: code },
          {
            mode: "cors",
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              Accept: "application/json",
              "API-KEY": "M4@fbCCG7N7gs]n@98$y888cKg[CQ][)",
              TOKEN: token,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          if (response.data.status === "ok" && response.data.code === 200) {
            navigate(`/${marketId}/ambassador/dashboard/`);
          } else {
            handleErrorResponse(response.data);
          }
        })
        .catch((error) => {
          console.error(error);
          handleErrorResponse(error.response.data);
        });
    } else {
      setErrorMessage("No prize data found.");
    }
  };

  const handleErrorResponse = (data) => {
    if (data.status === "error") {
      if (data.code === 422 && data.messages?.responseMessage) {
        setErrorMessage(data.messages.responseMessage);
      } else {
        setErrorMessage("Sorry, there was a problem claiming the prize.");
      }
    } else if (data.message) {
      setErrorMessage(data.message);
    }
  };

  return (
    <div className="page-center">
      <LogoutButton />
      <div className="text-center">
        {prizeData && (
          <div>
            {!prizeData.claimed && <h1 className="h6">Prize confirmed</h1>}
            {prizeData.claimed && (
              <h1 className="h6" style={{ color: "red" }}>
                This prize has already been claimed
              </h1>
            )}

            <h2 className="h1 mt-30">{prizeData.title}</h2>
            <div className="text-small mt-30">
              <p>Game Code: {prizeData.game}</p>
              <p>Prize Code: {prizeData.code}</p>
            </div>

            <div className="mt-30">
              {!prizeData.claimed && (
                <button className="button bg-sky-600 text-white mt-10" onClick={claimPrize}>
                  Mark prize as claimed
                </button>
              )}

              <Link
                className={clsx("button button--inline mt-10", { "button--small": !prizeData.claimed })}
                to={`/${marketId}/ambassador/dashboard`}
              >
                Close
              </Link>
            </div>

            {errorMessage && (
              <div className="mt-20" style={{ color: "red" }}>
                {errorMessage}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Claim;
