import axios from "axios";
import { handleApiError, handleApiResponse } from "../../../utils/apiHandlers";

const adminLogin = async (marketId, password) => {
  const url = `https://api.collect-and-win.com/api/client/login/m-${marketId}`;

  try {
    const response = await axios.post(
      url,
      { password },
      {
        mode: "cors",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Accept: "application/json",
          "API-KEY": "M4@fbCCG7N7gs]n@98$y888cKg[CQ][)",
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return handleApiResponse(response);
  } catch (error) {
    return handleApiError(error);
  }
};

export default adminLogin;
