import clsx from "clsx";
import useUserLoggedIn from "../../hooks/useUserLoggedIn";
import AdminLayout from "../../Layout/AdminLayout";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import getPage from "../../api/admin/pages/getPage";
import WYSIWYG from "../../partials/WYSIWYG";
import updatePage from "../../api/admin/pages/updatePage";
import deletePage from "../../api/admin/pages/deletePage";
import FormFeedback from "../../partials/formFeedback";

const PageAdd = () => {
  useUserLoggedIn();

  const [bodyString, setBodyString] = useState(null);
  function handleOutput(data) {
    setBodyString(data);
  }

  const navigate = useNavigate();
  const { pageId, eventId } = useParams();
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [formFeedback, setFormFeedback] = useState(false);
  const [formFeedbackList, setFormFeedbackList] = useState(false);
  const [formData, setFormData] = useState({
    event_id: eventId,
    identity: "",
    title: "",
    nav_title: "",
    intro: "",
    body: "",
    body_align: "",
    button_link: "",
    button_label: "",
    body_align: "",
    is_global: false,
  });

  useEffect(() => {
    if (pageId) {
      const fetchData = async () => {
        const res = await getPage(pageId); // Fetch the event data
        if (res.success) {
          const page = res.data;
          setFormData({
            event_id: eventId,
            identity: page.identity,
            title: page.title,
            nav_title: page.nav_title ? page.nav_title : page.title,
            intro: page.intro,
            body: page.body || "<p></p>",
            body_align: page.body_align,
            button_link: page.button_link,
            button_label: page.button_label,
          });
        } else {
          console.error("Error fetching event data:", res.error);
        }
      };

      fetchData();
    }
  }, [pageId]);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleUpdatePage = async () => {
    const updatePageRes = await updatePage(pageId, {
      ...Object.keys(formData)
        .filter((key) => formData[key] !== "" && formData[key] !== undefined && formData[key] !== null)
        .reduce((acc, key) => ({ ...acc, [key]: formData[key] }), {}),
      ...(bodyString ? { body: bodyString } : formData.body ? { body: formData.body } : {}),
    });
    if (updatePageRes.success) {
      navigate(-1);
    } else {
      // Handle error
      console.error("Error updating page:", updatePageRes.error);
      setFormFeedback(updatePageRes.error);
      setFormFeedbackList(updatePageRes.errorData);
    }
  };
  const handleDeleteClick = async () => {
    const deletePageRes = await deletePage(pageId);
    if (deletePageRes.success) {
      navigate(-1);
    } else {
      // Handle error
      console.error("Error deleting page:", deletePageRes.error);
      setFormFeedback(deletePageRes.error);
      setFormFeedbackList(deletePageRes.errorData ? deletePageRes.errorData : null);
    }
  };

  return (
    <AdminLayout>
      <div>
        <div className="section space-y-10">
          <div className="wrapper wrapper-md">
            <header className="space-y-5">
              <button className="font-bold" onClick={() => navigate(-1)}>
                &lt; Back
              </button>
              <div className="flex justify-between items-center">
                <h1>Edit Page</h1>
                <div className="flex gap-4">
                  <span className="button bg-red-600 text-white" onClick={() => setModalDeleteOpen(true)}>
                    Delete
                  </span>
                  <span className="button" onClick={() => navigate(-1)}>
                    Cancel
                  </span>
                  <span className="button bg-sky-600 text-white" onClick={handleUpdatePage}>
                    Save
                  </span>
                </div>
              </div>
            </header>
          </div>

          <div className="wrapper wrapper-md">
            <div className="space-y-4">
              {formFeedback && <FormFeedback feedback={formFeedback} feedbackList={formFeedbackList} />}
              <div className="flex items-center gap-4">
                <label className="font-bold w-1/3">Title</label>
                <input className="w-2/3" type="text" name="title" value={formData.title} onChange={handleChange} />
              </div>
              <div className="flex items-center gap-4">
                <label className="font-bold w-1/3">Nav Title</label>
                <input
                  className="w-2/3"
                  type="text"
                  name="nav_title"
                  value={formData.nav_title}
                  onChange={handleChange}
                />
              </div>

              <div className="flex items-center gap-4">
                <label className="font-bold w-1/3">Intro</label>
                <textarea className="w-2/3" rows="2" name="intro" value={formData.intro} onChange={handleChange} />
              </div>

              <div className="flex items-center gap-4">
                <label className="font-bold w-1/3">Body</label>
                <div className="w-2/3">
                  {formData && formData.body && <WYSIWYG content={formData?.body} output={handleOutput} />}
                </div>
              </div>

              <div className="flex items-center gap-4">
                <label className="font-bold w-1/3">Body text align</label>
                <select className="w-2/3" name="body_align" value={formData.body_align} onChange={handleChange}>
                  <option value="left">Left</option>
                  <option value="center">Center</option>
                  <option value="right">Right</option>
                </select>
              </div>

              <div className="flex items-center gap-4">
                <label className="font-bold w-1/3">Button label</label>
                <input
                  className="w-2/3"
                  name="button_label"
                  type="text"
                  value={formData.button_label}
                  onChange={handleChange}
                />
              </div>
              <div className="flex items-center gap-4">
                <label className="font-bold w-1/3">Button link</label>
                <input
                  className="w-2/3"
                  name="button_link"
                  type="text"
                  value={formData.button_link}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <aside
        className={clsx(
          "w-full h-full top-0 left-0 fixed bg-black/80 flex items-center justify-center transition-opacity",
          modalDeleteOpen ? "opacity-1" : "pointer-events-none opacity-0"
        )}
      >
        <div className="bg-white shadow p-10 space-y-4 max-w-2xl rounded">
          <h2>Are you sure you want to delete this page?</h2>
          <p>It will no longer be visible to users playing at this event.</p>
          <div className="flex gap-4">
            <span className="button" onClick={() => setModalDeleteOpen(false)}>
              Cancel
            </span>
            <span className="button bg-red-600 text-white" onClick={handleDeleteClick}>
              Delete
            </span>
          </div>
        </div>
      </aside>
    </AdminLayout>
  );
};

export default PageAdd;
