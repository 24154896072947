import clsx from "clsx";
import AdminLayout from "../../Layout/AdminLayout";
import useUserLoggedIn from "../../hooks/useUserLoggedIn";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import ToggleButton from "../../components/ToggleButton/ToggleButton";
import getEventThemes from "../../api/admin/events/getEventsThemes";
import getEvent from "../../api/admin/events/getEvent"; // Import the function to fetch the event data
import updateEvent from "../../api/admin/events/updateEvent"; // Import the function to update the event data
import deleteEvent from "../../api/admin/events/deleteEvent";
import { generate } from "random-words";
import FormFeedback from "../../partials/formFeedback";
import slugify from "slugify";

const EventEdit = () => {
  useUserLoggedIn();
  const navigate = useNavigate();
  const [formFeedback, setFormFeedback] = useState(false);
  const [formFeedbackList, setFormFeedbackList] = useState(false);
  const { marketId, eventId } = useParams();
  const [themes, setThemes] = useState([]);
  const password = useRef(null);
  const [startTime, setStartTime] = useState(false);
  const [endTime, setEndTime] = useState(false);
  const [eventActive, setEventActive] = useState(false);
  const [formData, setFormData] = useState({
    identity: "",
    title: "",
    start_date: "",
    end_date: "",
    start_time: "",
    end_time: "",
    theme_id: "",
    live: "",
    ambassador_password: "",
    new_event_id: "",
  });
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [modalPasswordOpen, setModalPasswordOpen] = useState(false);

  useEffect(() => {
    const now = new Date();
    const start = new Date(formData.start_date + " " + formData.start_time + " " + "UTC");
    const end = new Date(formData.end_date + " " + formData.end_time + " " + "UTC");
    if (start < now && end > now) {
      setEventActive(true);
    } else {
      setEventActive(false);
    }
  }, [formData.start_date, formData.start_time, formData.end_date, formData.end_time]);

  useEffect(() => {
    const fetchEventData = async () => {
      const eventRes = await getEvent(eventId); // Fetch the event data
      if (eventRes.success) {
        const event = eventRes.data;
        const pw = password.current ? password.current : event.ambassador_password;

        setStartTime(event.start_time);
        setEndTime(event.end_time);
        setFormData({
          title: event.title,
          start_date: event.start_date,
          end_date: event.end_date,
          start_time: event.start_time,
          end_time: event.end_time,
          theme_id: event.theme_identity,
          live: event.live === 1 ? true : false,
          ambassador_password: pw,
          identity: event.identity,
          new_event_id: event.identity,
        });
      } else {
        console.error("Error fetching event data:", eventRes.error);
      }
    };

    const fetchThemes = async () => {
      const themesRes = await getEventThemes();
      if (themesRes.success) {
        setThemes(themesRes.data);
      } else {
        setThemes({ error: true });
      }
    };

    fetchEventData();
    fetchThemes();
  }, [eventId, password]);

  const formatTime = (time) => {
    let t = time + ":00";
    return t;
  };

  const handleChange = (event) => {
    let { name, value, type, checked } = event.target;

    if (name === "start_time" || name === "end_time") {
      if (name === "start_time") {
        setStartTime(formatTime(value));
      }
      if (name === "end_time") {
        setEndTime(formatTime(value));
      }
    }

    const slugifyURL = (val) => {
      if (name === "new_event_id") {
        return val
          .toLowerCase()
          .replace(/\s+/g, "-") // Replace spaces with -
          .replace(/[^\w-]+/g, "") // Remove all non-word characters
          .replace(/--+/g, "-") // Replace multiple - with single -
          .replace(/^-+/, ""); // Trim - from start of text
      } else {
        return val;
      }
    };

    setFormData({
      ...formData,
      start_time: startTime,
      end_time: endTime,
      [name]: type === "checkbox" ? checked : slugifyURL(value),
    });
  };

  const handleToggleChange = (isChecked) => {
    setFormData((prevState) => ({
      ...prevState,
      live: isChecked,
    }));
  };

  const handleDeleteEvent = async () => {
    const deleteEventRes = await deleteEvent(eventId);
    if (deleteEventRes.success) {
      navigate(-1);
    }
  };

  const handlePasswordChange = () => {
    let pw = generate(3).toString();
    pw = pw.replaceAll(",", "-");
    setFormData({
      ...formData,
      ambassador_password: pw,
    });
  };

  const handleUpdateEvent = async () => {
    setFormFeedback(null);
    setFormFeedbackList(null);
    const updateEventRes = await updateEvent(eventId, {
      ...formData,
      identity: eventId,
      new_event_id: slugify(formData.new_event_id, "-"),
      password: formData.ambassador_password,
      live: formData.live ? 1 : 0,
      event_type: "static",
      start_time: startTime,
      end_time: endTime,
    });
    if (updateEventRes.success) {
      setFormFeedback(updateEventRes.message);
      setFormFeedbackList(null);
      navigate(`/${marketId}/events/${formData.new_event_id}`);
    } else {
      // Handle error
      console.error("Error updating event:", updateEventRes.error);
      setFormFeedback(updateEventRes.error);
      setFormFeedbackList(updateEventRes.errorData ? updateEventRes.errorData : null);
    }
  };

  return (
    <AdminLayout>
      <div>
        <div className="section space-y-10">
          <div className="wrapper wrapper-md">
            <header className="space-y-5">
              <button className="font-bold" onClick={() => navigate(-1)}>
                &lt; Back
              </button>
              <div className="flex justify-between items-center">
                <h1>Edit Event</h1>
                <div className="flex gap-4">
                  <Link
                    className={clsx("button bg-red-600 text-white", !eventActive ? "" : "!hidden")}
                    onClick={() => setModalDeleteOpen(true)}
                  >
                    Delete
                  </Link>

                  <span className="button" onClick={() => navigate(`/${marketId}/events`)}>
                    Cancel
                  </span>
                  <span className="button bg-sky-600 text-white" onClick={handleUpdateEvent}>
                    Save
                  </span>
                </div>
              </div>
            </header>
          </div>
          <div className="wrapper wrapper-md">
            <div className="space-y-4">
              {formFeedback && <FormFeedback feedback={formFeedback} feedbackList={formFeedbackList} />}
              <div className="flex items-center gap-4">
                <label className="font-bold w-1/3">Event name</label>
                <input className="w-2/3" type="text" name="title" value={formData.title} onChange={handleChange} />
              </div>
              <div className="flex items-center gap-4">
                <label className="font-bold w-1/3">Start date</label>
                <input
                  className="w-2/3"
                  type="date"
                  name="start_date"
                  value={formData.start_date}
                  onChange={handleChange}
                />
              </div>
              <div className="flex items-center gap-4">
                <label className="font-bold w-1/3">Start time</label>
                <input
                  className="w-2/3"
                  type="time"
                  name="start_time"
                  value={formData.start_time}
                  onChange={handleChange}
                />
                <input className="!hidden" type="text" value={startTime} onChange={handleChange} />
              </div>
              <div className="flex items-center gap-4">
                <label className="font-bold w-1/3">End date</label>
                <input
                  className="w-2/3"
                  type="date"
                  name="end_date"
                  value={formData.end_date}
                  onChange={handleChange}
                />
              </div>
              <div className="flex items-center gap-4">
                <label className="font-bold w-1/3">End time</label>
                <input
                  className="w-2/3"
                  type="time"
                  name="end_time"
                  value={formData.end_time}
                  onChange={handleChange}
                />
                <input className="!hidden" type="text" value={endTime} onChange={handleChange} />
              </div>
              <div className="flex items-center gap-4">
                <label className="font-bold w-1/3">Event URL</label>
                <input
                  className="w-2/3"
                  type="text"
                  name="new_event_id"
                  value={formData.new_event_id}
                  onChange={handleChange}
                />
              </div>
              <div className="flex items-center gap-4">
                <label className="font-bold w-1/3 pointer-events-none">Event password</label>
                <div className="w-2/3 gap-4 flex">
                  <input
                    className="w-2/3"
                    type="text"
                    name="ambassador_password"
                    value={formData.ambassador_password}
                    onChange={handleChange}
                  />
                  <button className="w-1/3 button bg-neutral-500 text-white" onClick={handlePasswordChange}>
                    new password
                  </button>
                </div>
              </div>
              <div className="flex items-center gap-4">
                <label className="font-bold w-1/3">Enabled</label>
                <ToggleButton
                  name="live"
                  checked={formData.live}
                  onChange={(e) => handleToggleChange(e.target.checked)}
                />
              </div>
            </div>
          </div>

          <div className="wrapper wrapper-md">
            <header>
              <h2>App content</h2>
            </header>
          </div>

          <div className="wrapper wrapper-md">
            <div className="space-y-4 divide-y divide-solid">
              <div className="flex items-center gap-4">
                <label className="font-bold w-1/3">Theme</label>
                <select className="w-2/3" name="theme_id" value={formData.theme_id} onChange={handleChange}>
                  <option value="" disabled>
                    Select a theme
                  </option>
                  {themes.map((theme) => (
                    <option key={theme.identity} value={theme.identity}>
                      {theme.title}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <aside
        className={clsx(
          "w-full h-full top-0 left-0 fixed bg-black/80 flex items-center justify-center transition-opacity",
          modalDeleteOpen ? "opacity-1" : "pointer-events-none opacity-0"
        )}
      >
        <div className="bg-white shadow p-10 space-y-4 max-w-2xl rounded">
          <div>Are you sure you want to delete the event</div>
          {formData?.title && <h2>{formData.title}</h2>}
          <p>
            All data for this event will be removed, including pins, pages and prizes. Users will no longer be able to
            play or claim prizes for this event.
          </p>
          <div className="flex gap-4">
            <span className="button" onClick={() => setModalDeleteOpen(false)}>
              Cancel
            </span>
            <Link className="button bg-red-600 text-white" onClick={() => handleDeleteEvent()}>
              Delete
            </Link>
          </div>
        </div>
      </aside>
      <aside
        className={clsx(
          "w-full h-full top-0 left-0 fixed bg-black/80 flex items-center justify-center transition-opacity",
          modalPasswordOpen ? "opacity-1" : "pointer-events-none opacity-0"
        )}
      >
        <div className="bg-white shadow p-10 space-y-4 max-w-2xl rounded">
          <h2>Are you sure you want to reset the event password?</h2>
          <p>Staff will no longer be able to login using the old password.</p>
          <div className="flex gap-4">
            <span className="button" onClick={() => setModalPasswordOpen(false)}>
              Cancel
            </span>
            <Link className="button bg-red-600 text-white">Change</Link>
          </div>
        </div>
      </aside>
    </AdminLayout>
  );
};

export default EventEdit;
