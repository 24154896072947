import AdminLayout from "../../Layout/AdminLayout";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ToggleButton from "../../components/ToggleButton/ToggleButton";
import getEventThemes from "../../api/admin/events/getEventsThemes";
import createEvent from "../../api/admin/events/createEvent";
import FormFeedback from "../../partials/formFeedback";
import { generate } from "random-words";
import slugify from "slugify";
import useUserLoggedIn from "../../hooks/useUserLoggedIn";

const EventAdd = () => {
  useUserLoggedIn();

  const navigate = useNavigate();
  const { marketId } = useParams();
  const [themes, setThemes] = useState([]);
  const [formFeedback, setFormFeedback] = useState(false);
  const [formFeedbackList, setFormFeedbackList] = useState(false);

  let pw = generate(3).toString();
  pw = pw.replaceAll(",", "-");

  const [formData, setFormData] = useState({
    title: "",
    start_date: "",
    end_date: "",
    start_time: "", // Added start_time
    end_time: "", // Added end_time
    theme_id: "",
    live: true,
    password: pw, // Assuming this is a static value
    event_id: "",
  });

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleToggleChange = (isChecked) => {
    setFormData((prevState) => ({
      ...prevState,
      live: isChecked,
    }));
  };

  useEffect(() => {
    const fetchEvents = async () => {
      const getEventThemesRes = await getEventThemes();
      if (getEventThemesRes.success) {
        setThemes(getEventThemesRes.data);
      } else {
        setThemes({ error: true });
      }
    };
    fetchEvents();
  }, []);

  const formatTime = (time) => {
    let t = time + ":00";
    return t;
  };

  const handleCreateEvent = async () => {
    const createEventRes = await createEvent({
      ...formData,
      live: formData.live === true ? 1 : 0,
      event_type: "static",
      start_time: formatTime(formData.start_time),
      end_time: formatTime(formData.end_time),
      event_id: formData.title
        .toLowerCase()
        .replace(/\s+/g, "-") // Replace spaces with -
        .replace(/[^\w-]+/g, "") // Remove all non-word characters
        .replace(/--+/g, "-") // Replace multiple - with single -
        .replace(/^-+/, "") // Trim - from start of text
        .replace(/-+$/, ""), // Trim - from end of text,
    });
    if (createEventRes.success) {
      navigate(`/${marketId}/events`);
    } else {
      // Handle error
      console.error("Error creating event:", createEventRes.error);
      setFormFeedback(createEventRes.error);
      setFormFeedbackList(createEventRes.errorData ? createEventRes.errorData : null);
    }
  };

  return (
    <AdminLayout>
      <div>
        <div className="section space-y-10">
          <div className="wrapper wrapper-md">
            <header className="space-y-5">
              <button className="font-bold" onClick={() => navigate(`/${marketId}/events`)}>
                &lt; Events
              </button>
              <div className="flex justify-between items-center">
                <h1>Add Event</h1>
                <div className="flex gap-4">
                  <span className="button" onClick={() => navigate(`/${marketId}/events`)}>
                    Cancel
                  </span>
                  <span className="button bg-sky-600 text-white" onClick={handleCreateEvent}>
                    Save
                  </span>
                </div>
              </div>
            </header>
          </div>
          <div className="wrapper wrapper-md">
            <div className="space-y-4">
              {formFeedback && <FormFeedback feedback={formFeedback} feedbackList={formFeedbackList} />}
              <div className="flex items-center gap-4">
                <label className="font-bold w-1/3">Event name</label>
                <input className="w-2/3" type="text" name="title" value={formData.title} onChange={handleChange} />
              </div>
              <div className="flex items-center gap-4">
                <label className="font-bold w-1/3">Start date</label>
                <input
                  className="w-2/3"
                  type="date"
                  name="start_date"
                  value={formData.start_date}
                  onChange={handleChange}
                />
              </div>
              <div className="flex items-center gap-4">
                <label className="font-bold w-1/3">Start time</label>
                <input
                  className="w-2/3"
                  type="time"
                  name="start_time"
                  value={formData.start_time}
                  onChange={handleChange}
                />
              </div>
              <div className="flex items-center gap-4">
                <label className="font-bold w-1/3">End date</label>
                <input
                  className="w-2/3"
                  type="date"
                  name="end_date"
                  value={formData.end_date}
                  onChange={handleChange}
                />
              </div>
              <div className="flex items-center gap-4">
                <label className="font-bold w-1/3">End time</label>
                <input
                  className="w-2/3"
                  type="time"
                  name="end_time"
                  value={formData.end_time}
                  onChange={handleChange}
                />
              </div>
              <div className="flex items-center gap-4">
                <label className="font-bold w-1/3">Event URL</label>
                <input
                  className="w-2/3"
                  type="text"
                  name="event_id"
                  value={
                    formData.title
                      .toLowerCase()
                      .replace(/\s+/g, "-") // Replace spaces with -
                      .replace(/[^\w-]+/g, "") // Remove all non-word characters
                      .replace(/--+/g, "-") // Replace multiple - with single -
                      .replace(/^-+/, "") // Trim - from start of text
                      .replace(/-+$/, "") // Trim - from end of text
                  }
                  onChange={handleChange}
                  disabled
                />
              </div>
              {/* <div className="flex items-center gap-4">
                <label className="font-bold w-1/3">Event URL</label>
                <input disabled className="w-2/3" type="text" value="ABC123" />
              </div>
              <div className="flex items-center gap-4">
                <label className="font-bold w-1/3">Event password</label>
                <input disabled className="w-2/3" type="text" value="thepassword" />
              </div> */}
              <div className="flex items-center gap-4">
                <label className="font-bold w-1/3">Enabled</label>
                <ToggleButton name="live" checked={formData.live} onChange={handleToggleChange} />
              </div>
            </div>
          </div>

          <div className="wrapper wrapper-md">
            <header>
              <h2>App content</h2>
            </header>
          </div>

          <div className="wrapper wrapper-md">
            <div className="space-y-4 divide-y divide-solid">
              <div className="flex items-center gap-4">
                <label className="font-bold w-1/3">Theme</label>
                <select className="w-2/3" name="theme_id" value={formData.theme_id} onChange={handleChange}>
                  <option value="" disabled>
                    Select a theme
                  </option>
                  {themes.map((theme) => (
                    <option key={theme.identity} value={theme.identity}>
                      {theme.title}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default EventAdd;
