const ToggleButton = ({ name, checked = false, onChange }) => {
  return (
    <label className="inline-flex items-center cursor-pointer">
      <input
        type="checkbox"
        name={name}
        className="sr-only peer"
        checked={checked}
        // onClick={() => {
        //   setIsChecked(!isChecked);
        // }}
        onChange={onChange}
      />
      <div className="relative w-11 h-6 bg-gray-300 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-emerald-400"></div>
    </label>
  );
};

export default ToggleButton;
