import clsx from "clsx";
import { useCallback, useTransition } from "react";
import { EditorContent, JSONContent, useEditor } from "@tiptap/react";
// import Document from "@tiptap/extension-document";
// import Link from "@tiptap/extension-link";
import StarterKit from "@tiptap/starter-kit";
import * as Icons from "./Icons";

const WYSIWYG = ({ content, output }) => {
  const [, startTransition] = useTransition();

  const editor = useEditor({
    content: content,
    extensions: [StarterKit],
    onUpdate({ editor }) {
      const json = editor.getHTML();
      startTransition(() => output(json));
    },
  });

  const toggleItalic = useCallback(() => {
    editor.chain().focus().toggleItalic().run();
  }, [editor]);
  const toggleBold = useCallback(() => {
    editor.chain().focus().toggleBold().run();
  }, [editor]);
  const toggleBulletList = useCallback(() => {
    editor.chain().focus().toggleBulletList().run();
  }, [editor]);
  //   const toggleUnderline = useCallback(() => {
  //     editor.chain().focus().toggleUnderline().run();
  //   }, [editor]);

  return (
    <div className="wysiwyg-editor">
      <header className="wysiwyg-toolbar">
        {/* <button className={clsx("wysiwyg-toolbar-button", editor?.isActive("underline"))} onClick={toggleUnderline}>
          <Icons.Underline />
        </button> */}
        <button className={clsx("wysiwyg-toolbar-button", editor?.isActive("bold") && "active")} onClick={toggleBold}>
          <Icons.Bold />
        </button>
        <button className={clsx("wysiwyg-toolbar-button", editor?.isActive("intalic"))} onClick={toggleItalic}>
          <Icons.Italic />
        </button>
        <button className={clsx("wysiwyg-toolbar-button", editor?.isActive("bulletList"))} onClick={toggleBulletList}>
          <Icons.BulletList />
        </button>
      </header>
      <div className="wysiwyg-editor-body">
        <EditorContent editor={editor} />
      </div>
    </div>
  );
};

export default WYSIWYG;
