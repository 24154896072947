const handleApiResponse = (response) => {
  const { status, code, messages, data } = response.data;

  if (status === "ok" && code === 200) {
    return {
      success: true,
      data,
      message: messages.responseMessage,
    };
  } else {
    return {
      success: false,
      error: messages.responseMessage,
      errorCode: messages.responseCode,
    };
  }
};

const handleApiError = (error) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    return {
      success: false,
      error: error.response.data.messages.responseMessage,
      errorData: error.response.data.data,
      errorCode: error.response.data.messages.responseCode,
    };
  } else if (error.request) {
    // The request was made but no response was received
    return {
      success: false,
      error: "No response received from server.",
    };
  } else {
    // Something happened in setting up the request that triggered an Error
    return {
      success: false,
      error: error.message,
    };
  }
};

export { handleApiResponse, handleApiError };
