import clsx from "clsx";
import useUserLoggedIn from "../../hooks/useUserLoggedIn";
import AdminLayout from "../../Layout/AdminLayout";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import getPrize from "../../api/admin/prizes/getPrize";
import updatePrize from "../../api/admin/prizes/updatePrize";
import deletePrize from "../../api/admin/prizes/deletePrize";
import ToggleButton from "../../components/ToggleButton/ToggleButton";
import FormFeedback from "../../partials/formFeedback";

const PrizeGlobalEdit = () => {
  useUserLoggedIn();
  const navigate = useNavigate();
  const { prizeId } = useParams();
  // const [percentageValue, setPercentageValue] = useState("1");
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [formFeedback, setFormFeedback] = useState(false);
  const [formFeedbackList, setFormFeedbackList] = useState(false);
  const [formData, setFormData] = useState({
    live: "",
    identity: "",
    title: "",
    // configs: [
    //   {
    //     live: "",
    //     identity: "",
    //     prize_mechanic: "",
    //     prize_delivery: "",
    //     prize_type: "",
    //     chance_value: "",
    //     frequency_period: "",
    //     frequency: "",
    //   },
    // ],
  });

  useEffect(() => {
    if (prizeId) {
      const fetchData = async () => {
        const prizeRes = await getPrize(prizeId); // Fetch the prize data
        if (prizeRes.success) {
          const prize = prizeRes.data;
          setFormData({
            live: prize.live === 1 ? true : false,
            identity: prize.identity,
            title: prize.title,
            // configs: [
            //   {
            //     live: prize.live,
            //     identity: prize.identity,
            //     prize_mechanic: prize.prize_mechanic,
            //     prize_delivery: prize.prize_delivery,
            //     prize_type: prize.prize_type,
            //     chance_value: prize.chance_value,
            //     frequency_period: prize.frequency_period,
            //     frequency: prize.frequency,
            //   },
            // ],
          });
        } else {
          console.error("Error fetching prize data:", prizeRes.error);
        }
      };

      fetchData();
    }
  }, [prizeId]);

  const handleChange = (prize) => {
    const { name, value, type, checked } = prize.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleUpdatePrize = async () => {
    const updatePrizeRes = await updatePrize(prizeId, {
      ...formData,
      live: formData.live ? 1 : 0,
    });
    if (updatePrizeRes.success) {
      navigate(-1);
    } else {
      // Handle error
      console.error("Error updating page:", updatePrizeRes.error);
      setFormFeedback(updatePrizeRes.error);
      setFormFeedbackList(updatePrizeRes.errorData ? updatePrizeRes.errorData : null);
    }
  };

  const handleToggleChange = (isChecked) => {
    setFormData((prevState) => ({
      ...prevState,
      live: isChecked,
    }));
  };

  const handleDeletePrize = async () => {
    const deleteEventRes = await deletePrize(prizeId);
    if (deleteEventRes.success) {
      navigate(-1);
    }
  };

  return (
    <AdminLayout>
      <div>
        <div className="section space-y-10">
          <div className="wrapper wrapper-md">
            <header className="space-y-5">
              <button className="font-bold" onClick={() => navigate(-1)}>
                &lt; Back
              </button>
              <div className="flex justify-between items-center">
                <h1>Edit Prize</h1>
                <div className="flex gap-4">
                  <span className="button" onClick={() => navigate(-1)}>
                    Cancel
                  </span>
                  <span className="button bg-red-600 text-white" onClick={() => setModalDeleteOpen(true)}>
                    Delete
                  </span>
                  <span className="button bg-sky-600 text-white" onClick={handleUpdatePrize}>
                    Save
                  </span>
                </div>
              </div>
            </header>
          </div>

          <div className="wrapper wrapper-md">
            <div className="space-y-4">
              {formFeedback && <FormFeedback feedback={formFeedback} feedbackList={formFeedbackList} />}
              <div className="flex items-center gap-4">
                <label className="font-bold w-1/3">Title</label>
                <input className="w-2/3" type="text" name="title" value={formData.title} onChange={handleChange} />
              </div>

              <div className="flex items-center gap-4">
                <label className="font-bold w-1/3">Enabled</label>
                <ToggleButton
                  name="live"
                  checked={formData.live}
                  onChange={(e) => handleToggleChange(e.target.checked)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <aside
        className={clsx(
          "w-full h-full top-0 left-0 fixed bg-black/80 flex items-center justify-center transition-opacity",
          modalDeleteOpen ? "opacity-1" : "pointer-events-none opacity-0"
        )}
      >
        <div className="bg-white shadow p-10 space-y-4 max-w-2xl rounded">
          <h2>Are you sure you want to delete this prize?</h2>
          <p>Users will no longer be able to win or claim this prize.</p>
          <div className="flex gap-4">
            <span className="button" onClick={() => setModalDeleteOpen(false)}>
              Cancel
            </span>
            <Link className="button bg-red-600 text-white" onClick={() => handleDeletePrize()}>
              Delete
            </Link>
          </div>
        </div>
      </aside>
    </AdminLayout>
  );
};

export default PrizeGlobalEdit;
