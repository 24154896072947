const Home = () => {
  return (
    <div className="page-center">
      <div className="text-center">
        <h1>Collect &amp; Win</h1>
        <p>Please ensure your URL contains a valid market ID</p>
      </div>
    </div>
  );
};

export default Home;
