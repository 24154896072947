import useUserLoggedIn from "../../hooks/useUserLoggedIn";
import AdminLayout from "../../Layout/AdminLayout";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Icons from "../../partials/Icons";
import getGlobalPages from "../../api/admin/pages/getGlobalPages";

const PagesGlobal = () => {
  useUserLoggedIn();
  const navigate = useNavigate();
  const { marketId } = useParams();
  const [pagesData, setPagesData] = useState("");

  useEffect(() => {
    const fetchPages = async () => {
      const getPagesRes = await getGlobalPages();
      if (getPagesRes.success) {
        setPagesData(getPagesRes.data);
      } else {
        setPagesData({ error: true });
      }
    };
    fetchPages();
  }, []);

  return (
    <AdminLayout>
      <div className="wrapper wrapper-md">
        <div className="section space-y-10">
          <header className="space-y-5">
            <button className="font-bold" onClick={() => navigate(-1)}>
              &lt; Back
            </button>
            <div className="flex justify-between items-center">
              <h1>Global Pages</h1>
              <Link className="button bg-sky-600 text-white" to={`/${marketId}/admin/pages/global/add`}>
                Add a page
              </Link>
            </div>
          </header>
          {pagesData && <Pages pagesData={pagesData} />}
        </div>
      </div>
    </AdminLayout>
  );
};

const Pages = ({ pagesData }) => {
  const navigate = useNavigate();
  const { marketId } = useParams();

  if (pagesData.length && pagesData.length > 0) {
    return (
      <div className="space-y-4 divide-y divide-solid">
        {pagesData
          .sort((a, b) => a.title.localeCompare(b.title))
          .map((page, key) => {
            return (
              <div key={key} className="flex gap-4 py-4 flex items-center">
                {page.title && (
                  <span
                    className="text-xl cursor-pointer w-2/3"
                    onClick={() => navigate(`/${marketId}/admin/pages/global/edit/${page.identity}`)}
                  >
                    {page.title}
                  </span>
                )}
                <span className="w-1/3 text-right flex justify-end items-center">
                  <span className="inline-block">
                    <span className="inline-block">
                      <Icons.ChevronRight size="20" />
                    </span>
                  </span>
                </span>
              </div>
            );
          })}
      </div>
    );
  }
};

export default PagesGlobal;
