import clsx from "clsx";
import useUserLoggedIn from "../../hooks/useUserLoggedIn";
import AdminLayout from "../../Layout/AdminLayout";
import { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { formatDate } from "../../utils/formatDate";
import getPins from "../../api/admin/pins/getPins";
import Map from "../Map/Map";

import sortPages from "../../api/admin/pages/sortPages";
import getEvent from "../../api/admin/events/getEvent";
import getAlllRelevantPages from "../../api/admin/pages/getAlllRelevantPages";
import getEventPrizes from "../../api/admin/prizes/getEventPrizes";
import { generateRandomColour } from "../../utils/generateRandomColour";
import * as Icons from "../../partials/Icons";
// drag sortable
import { Reorder } from "framer-motion";
import getStatsByEvent from "../../api/admin/stats/getStatsByEvent";

const EventPage = () => {
  useUserLoggedIn();
  const navigate = useNavigate();
  const { marketId, eventId } = useParams();
  const [activeTab, setActiveTab] = useState(localStorage.getItem("activeTab") || "stats");
  const [pinsData, setPinsData] = useState("");
  const [prizesData, setPrizesData] = useState("");
  const [pagesData, setPagesData] = useState("");
  const [eventData, setEventData] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    localStorage.setItem("activeTab", activeTab);
  }, [activeTab]);

  useEffect(() => {
    if (eventId) {
      const fetchPins = async () => {
        const getPinsRes = await getPins(eventId);
        if (getPinsRes.success) {
          setPinsData(getPinsRes.data);
        } else {
          setPinsData({ error: true });
        }
      };
      fetchPins();
    }
  }, [eventId]);

  useEffect(() => {
    if (eventId) {
      const fetchPrizes = async () => {
        const getPrizesRes = await getEventPrizes(eventId);
        if (getPrizesRes.success) {
          setPrizesData(getPrizesRes.data);
        } else {
          setPrizesData({ error: true });
        }
      };
      fetchPrizes();
    }
  }, [eventId]);

  useEffect(() => {
    if (eventId) {
      const fetchPages = async () => {
        const getPagesRes = await getAlllRelevantPages(eventId);
        if (getPagesRes.success) {
          setPagesData(getPagesRes.data);
        } else {
          setPagesData({ error: true });
        }
      };
      fetchPages();
    }
  }, [eventId]);

  useEffect(() => {
    if (eventId) {
      const fetchEvent = async () => {
        const getEventRes = await getEvent(eventId);
        if (getEventRes.success) {
          setEventData(getEventRes.data);
        } else {
          setEventData({ error: true });
        }
      };
      fetchEvent();
    }
  }, [eventId]);

  return (
    <AdminLayout>
      <div className="wrapper page">
        <div className="section space-y-10">
          <header className="space-y-5">
            <button className="font-bold" onClick={() => navigate(-1)}>
              &lt; Events
            </button>
            <div className="flex justify-between items-center">
              <header className="flex items-center gap-4">
                <h1 className="text-2xl md:text-4xl">{eventData.title}</h1>
                {eventData.live === 0 && <span className="button button-notice bg-red-100 grow-0">Disabled</span>}
                {eventData.live === 1 && <span className="button button-notice bg-emerald-100 grow-0">Enabled</span>}
              </header>
              <Link className="button bg-sky-600 text-white" to={`/${marketId}/events/edit/${eventId}`}>
                Edit event
              </Link>
            </div>
            <div className="flex gap-4 flex-wrap items-center">
              {eventData.start_date && eventData.end_date && (
                <span className="button button-notice bg-sky-100">
                  {formatDate(eventData.start_date, true)} - {formatDate(eventData.end_date, true)}
                </span>
              )}
            </div>
            <div className="flex gap-4 flex-wrap items-center">
              {eventData.identity && (
                <p>
                  Event URL:{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://www.collect-and-win.com/${marketId}/${eventData.identity}`}
                    className="font-bold"
                  >{`collect-and-win.com/${marketId}/${eventData.identity}`}</a>
                </p>
              )}
            </div>
            <div className="flex gap-4 flex-wrap items-center !mt-0">
              {eventData.identity && (
                <p>
                  Ambassador URL:{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${window.location.origin.toString()}/${marketId}`}
                    className="font-bold"
                  >{`${window.location.origin.toString()}/${marketId}`}</a>
                </p>
              )}
            </div>
            <div className="flex gap-4 flex-wrap items-center !mt-0">
              {eventData.ambassador_password && (
                <p>
                  Ambassador Password: <span className="font-bold">{eventData.ambassador_password}</span>
                </p>
              )}
            </div>
          </header>
          <nav className="flex items-center justify-between flex-wrap gap-4">
            <ul className="rounded-lg overfllow-hidden border-2 border-neutral-300 p-0.5 flex gap-0.5">
              <li>
                <button
                  className={clsx("button", activeTab && activeTab === "stats" && "bg-neutral-300")}
                  onClick={() => setActiveTab("stats")}
                >
                  Analytics
                </button>
              </li>
              <li>
                <button
                  className={clsx("button", activeTab && activeTab === "pins" && "bg-neutral-300")}
                  onClick={() => setActiveTab("pins")}
                >
                  Pins
                </button>
              </li>
              <li>
                <button
                  className={clsx("button", activeTab && activeTab === "prizes" && "bg-neutral-300")}
                  onClick={() => setActiveTab("prizes")}
                >
                  Prizes
                </button>
              </li>
              <li>
                <button
                  className={clsx("button", activeTab && activeTab === "pages" && "bg-neutral-300")}
                  onClick={() => setActiveTab("pages")}
                >
                  Pages
                </button>
              </li>
            </ul>
            {activeTab && activeTab === "pins" && (
              <button
                className="button bg-sky-600 text-white justify-between"
                onClick={() => navigate(`/${marketId}/admin/${eventId}/pins/edit`)}
              >
                {pinsData?.length && pinsData.length > 0 ? "Edit pins" : "Add pins"}
              </button>
            )}
            {activeTab && activeTab === "prizes" && (
              <button
                className="button bg-sky-600 text-white justify-between"
                onClick={() => navigate(`/${marketId}/events/${eventId}/prizes/add`)}
              >
                {/* {prizesData?.length && prizesData.length > 0 ? "Edit prizes" : "Add prizes"} */}
                Add prizes
              </button>
            )}
            {activeTab && activeTab === "pages" && (
              <button
                className="button bg-sky-600 text-white justify-between"
                onClick={() => navigate(`/${marketId}/admin/pages/add/${eventId}`)}
              >
                Add a page
              </button>
            )}
          </nav>
          {pinsData && activeTab && activeTab === "pins" && <Pins pinsData={pinsData} />}
          {prizesData && activeTab && activeTab === "prizes" && <Prizes prizesData={prizesData} />}
          {pagesData && activeTab && activeTab === "pages" && (
            <Pages setModalOpen={setModalOpen} pagesData={pagesData} setPagesData={setPagesData} />
          )}
          {activeTab === "stats" && <Stats></Stats>}
        </div>
      </div>
      <aside
        className={clsx(
          "w-full h-full top-0 left-0 fixed bg-black/80 flex items-center justify-center transition-opacity z-10",
          modalOpen ? "opacity-1" : "pointer-events-none opacity-0"
        )}
      >
        <div className="bg-white shadow p-10 space-y-4 max-w-2xl rounded">
          <h3>Global pages cannot be eddited here</h3>
          {/* {modalDeleteId.current !== null && (
            <h2>{formData.find((pin) => pin.identity === modalDeleteId.current)?.title}</h2>
          )} */}
          <p>
            Global pages may be used by multiple events. To edit them, please visit the "Global Pages" section of the
            admin.
          </p>
          <div className="flex gap-4 float-right">
            <span className="button bg-sky-600 text-white" onClick={() => setModalOpen(false)}>
              OK
            </span>
          </div>
        </div>
      </aside>
    </AdminLayout>
  );
};

const Stats = () => {
  const { marketId, eventId } = useParams();
  const [stats, setStats] = useState(undefined);
  const [pins, setPins] = useState(undefined);
  const [selectedDate, setSelectedDate] = useState("total");

  useEffect(() => {
    const getStats = async () => {
      const res = await getStatsByEvent(marketId, eventId);

      if (res.success) {
        setStats(res.data.data[eventId.toLowerCase().replace(/-/g, "_")]);
        setPins(res.data.pins);
      }
    };

    getStats();
  }, [marketId, eventId]);

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  if (!stats || !pins) return null;

  const data = selectedDate === "total" ? stats.total : stats.daily[selectedDate];

  if (!data) return <div>No data available for this date.</div>;

  const gamesCreated = data.created;
  const gamesGenerated = data.generated;
  const finishedGames = data.claimed + data.won + data.lost;
  const gamesIncomplete = gamesGenerated - finishedGames;
  const claimedPrizes = data.claimed;

  return (
    <>
      <div className="grid grid-cols-12 gap-8">
        <select className="col-span-2" id="date-select" onChange={handleDateChange} value={selectedDate}>
          <option value="total">Total</option>
          {Object.keys(stats.daily).map((date) => (
            <option key={date} value={date}>
              {date}
            </option>
          ))}
        </select>
      </div>
      <div className="grid grid-cols-12 gap-8">
        <div className="col-span-6 md:col-span-2 flex flex-col">
          <span className="text-5xl pb-2 font-bold">{gamesCreated}</span>
          <span>Games Created</span>
        </div>
        <div className="col-span-6 md:col-span-2 flex flex-col">
          <span className="text-5xl pb-2 font-bold">{gamesGenerated}</span>
          <span>Games Started</span>
        </div>
        <div className="col-span-6 md:col-span-2 flex flex-col">
          <span className="text-5xl pb-2 font-bold">{gamesIncomplete}</span>
          <span>Incomplete Games</span>
        </div>
        <div className="col-span-6 md:col-span-2 flex flex-col">
          <span className="text-5xl pb-2 font-bold">{finishedGames}</span>
          <span>Finished Games</span>
        </div>
        <div className="col-span-6 md:col-span-2 flex flex-col">
          <span className="text-5xl pb-2 font-bold">{claimedPrizes}</span>
          <span>Prizes Claimed</span>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-8">
        <div className="col-span-6 md:col-span-2 flex flex-col">
          <span className="font-bold mb-4">Pins Found</span>
        </div>
      </div>
      {data.pins_by_id &&
        Object.entries(data.pins_by_id).map(([id, total]) => {
          const pin = pins[id.toLowerCase()]; // Handle case insensitivity
          if (!pin) return null; // Handle missing pin details
          return (
            <div className="grid grid-cols-12 gap-8 !mt-0" key={id}>
              <div className="col-span-4 md:col-span-3 flex gap-2">
                <div
                  className="w-4 h-4 shrink-0 rounded-full border border-black relative top-1"
                  style={{ background: pin.colour }}
                ></div>
                <span>{pin.title}</span>
              </div>
              <div className="col-span-4 md:col-span-3 flex flex-col">
                <span>{pin.description}</span>
              </div>
              <div className="col-span-4 md:col-span-2 flex flex-col">
                <span>{total}</span>
              </div>
            </div>
          );
        })}

      <div className="grid grid-cols-12 gap-8">
        <div className="col-span-6 md:col-span-2 flex flex-col">
          <span className="font-bold mb-4">Dropoff</span>
        </div>
      </div>
      {Object.values(data.pins_found).map((value, index) => (
        <div className="grid grid-cols-12 gap-8 !mt-0" key={index}>
          <div className="col-span-4 md:col-span-2 flex flex-col">
            <span>{`${index + 1} Pin${index + 1 > 1 ? "s" : ""} Found`}</span>
          </div>
          <div className="col-span-4 md:col-span-2 flex flex-col">
            <span>{value}</span>
          </div>
        </div>
      ))}
    </>
  );
};

const Pins = ({ pinsData }) => {
  if (pinsData.length && pinsData.length > 0) {
    return (
      <div className="grid grid-cols-12 gap-8">
        <div className="col-span-12 md:col-span-6">
          <figure className="aspect-video w-full rounded bg-neutral-500">
            <Map initialPins={pinsData} />
          </figure>
        </div>
        <div className="col-span-12 md:col-span-6 text-xs md:text-md">
          <ul className="divide-y divide-solid animate-fade-in">
            {pinsData.map((pin, key) => {
              return (
                <li key={key} className={"flex gap-4 py-4 flex justify-between"}>
                  {pin.title && (
                    <div className="w-2/5 flex gap-2">
                      <div
                        className="w-4 h-4 shrink-0 rounded-full border border-black relative top-1"
                        style={{ background: pin.pin_colour ? pin.pin_colour : generateRandomColour() }}
                      ></div>
                      <span className="">{pin.title}</span>
                    </div>
                  )}
                  {pin.description && (
                    <div className="text-center w-2/5">
                      <span>{pin.description}</span>
                    </div>
                  )}
                  <div className="text-right w-1/5">
                    <span className={clsx(pin.live === 1 ? "text-green-600" : "text-red-600")}>
                      {pin.live === 1 ? "Enabled" : "Disabled"}
                    </span>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  } else {
    return (
      <div className="grid grid-cols-2 gap-8">
        <span className="">
          It looks like there are currently no pins set up for this event. Please create and manage prize options
          through the add pins button.
        </span>
      </div>
    );
  }
};

const Prizes = ({ prizesData }) => {
  const navigate = useNavigate();
  const { marketId, eventId } = useParams();
  if (prizesData.length && prizesData.length > 0) {
    return (
      <ul className="divide-y divide-solid animate-fade-in">
        {prizesData.map((prize, key) => {
          return (
            <li
              key={key}
              className="flex gap-4 py-4 flex items-center justify-between text-xs md:text-lg"
              onClick={() => navigate(`/${marketId}/admin/prizes/edit/${eventId}/${prize.configs[0].identity}`)}
            >
              {prize.title && <p className="w-1/5 ">{prize.title}</p>}
              {prize.configs[0].prize_type && <p className="w-1/5">{prize.configs[0].prize_type.replace(/_/g, " ")}</p>}
              {prize.configs[0].prize_delivery && (
                <p className="w-1/5">{prize.configs[0].prize_delivery.replace(/_/g, " ")}</p>
              )}
              <div className="flex flex items-center justify-between w-1/5">
                <span className={clsx(prize.configs[0].live === 1 ? "text-green-600" : "text-red-600")}>
                  {prize.configs[0].live === 1 ? "Enabled" : "Disabled"}
                </span>
                <span className="inline-block">
                  <span className="inline-block">
                    <Icons.ChevronRight size="20" />
                  </span>
                </span>
              </div>
            </li>
          );
        })}
      </ul>
    );
  } else {
    return (
      <div className="grid grid-cols-2 gap-8">
        <span className="">
          It looks like there are currently no prizes set up for this event. Please create and manage prize options
          through the add prize button.
        </span>
      </div>
    );
  }
};

const Pages = ({ pagesData, setPagesData, setModalOpen }) => {
  const navigate = useNavigate();
  const { marketId, eventId } = useParams();
  const [published, setPublished] = useState(false);
  const [unpublished, setUnpublished] = useState([]);
  const [unpublishedGlobal, setUnpublishedGlobal] = useState([]);

  const publishedCount = useRef(null);

  useEffect(() => {
    if (pagesData && pagesData.length > 0) {
      const temp = [];
      pagesData.map((page) => {
        if (page.published) {
          temp.push(page);
        }
      });
      setPublished(temp);
      publishedCount.current = temp;
    }
  }, [pagesData]);
  useEffect(() => {
    if (pagesData && pagesData.length > 0) {
      const temp = [];
      pagesData &&
        pagesData.map((page) => {
          if (!page.published && !page.is_global) {
            temp.push(page);
          }
        });
      setUnpublished(temp);
    }
  }, [pagesData]);

  useEffect(() => {
    if (pagesData && pagesData.length > 0) {
      const temp = [];
      pagesData &&
        pagesData.map((page) => {
          if (!page.published && page.is_global) {
            temp.push(page);
          }
        });
      setUnpublishedGlobal(temp);
    }
  }, [pagesData]);

  const handlePublishClick = async (pageData) => {
    const isPublished = !pageData.published;
    let tempArray;
    if (!isPublished) {
      tempArray = published.filter((item) => item.identity !== pageData.identity);
    } else {
      tempArray = [...published, pageData];
    }
    setPublished(tempArray);
  };

  useEffect(() => {
    const getPages = async () => {
      const getPagesRes = await getAlllRelevantPages(eventId);
      if (getPagesRes.success) {
        setPagesData(getPagesRes.data);
      }
    };
    // FIX THIS BELOW
    if (published instanceof Array && !arraysEqual(published, publishedCount.current)) {
      const t = published && published.length > 0 ? published.map((page) => page.identity).toString() : "clear";

      const sortRequest = async () => {
        const sort = await sortPages(eventId, t);
        if (sort.success) {
          getPages();
          publishedCount.current = published;
        }
      };
      sortRequest();
    }
  }, [published, eventId, setPagesData]);

  function arraysEqual(arr1, arr2) {
    // Check if the arrays have the same length
    if (arr1.length !== arr2.length) {
      return false;
    }
    // Compare elements one by one
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }
    // If no elements differ, the arrays are the same
    return true;
  }

  if (pagesData.length && pagesData.length > 0) {
    return (
      <>
        {published.length > 0 && (
          <div>
            <h3>Published pages</h3>
            <Reorder.Group
              axis="y"
              values={published}
              onReorder={setPublished}
              className="divide-y divide-solid animate-fade-in"
            >
              {published.map((page) => (
                <Reorder.Item
                  key={page.identity}
                  value={page}
                  className="flex gap-4 py-4 flex items-center justify-between"
                >
                  <div className="flex gap-4 flex items-center">
                    <span className="inline-block cursor-pointer">
                      <Icons.Reorder size="18" />
                    </span>
                    {page.title && (
                      <span
                        className="text-l md:text-xl cursor-pointer"
                        onClick={() =>
                          page.is_global
                            ? setModalOpen(true)
                            : navigate(`/${marketId}/admin/pages/edit/${eventId}/${page.identity}`)
                        }
                      >
                        {page.title}
                      </span>
                    )}
                    {page.is_global && <span className="button button-notice bg-sky-100">Global</span>}
                  </div>
                  <div>
                    <button className="button text-sm" onClick={() => handlePublishClick(page)}>
                      Unpublish
                    </button>
                  </div>
                </Reorder.Item>
              ))}
            </Reorder.Group>
          </div>
        )}
        {unpublished.length > 0 && (
          <div>
            <h3>Unpublished pages</h3>
            <ul className="divide-y divide-solid animate-fade-in">
              {unpublished
                .sort((a, b) => a.title.localeCompare(b.title))

                .map((page) => (
                  <li key={page.identity} className="flex gap-4 py-4 flex items-center justify-between">
                    <div className="flex gap-4 flex items-center">
                      {page.title && (
                        <span
                          className="text-l md:text-xl cursor-pointer"
                          onClick={() =>
                            page.is_global
                              ? setModalOpen(true)
                              : navigate(`/${marketId}/admin/pages/edit/${eventId}/${page.identity}`)
                          }
                        >
                          {page.title}
                        </span>
                      )}
                      {page.is_global && <span className="button button-notice bg-sky-100">Global</span>}
                    </div>
                    <div>
                      <button
                        className="button text-sm bg-emerald-400 text-white"
                        onClick={() => handlePublishClick(page)}
                      >
                        Publish
                      </button>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        )}
        {unpublishedGlobal.length > 0 && (
          <div>
            <h3>Unpublished global pages</h3>
            <ul className="divide-y divide-solid animate-fade-in">
              {unpublishedGlobal
                .sort((a, b) => a.title.localeCompare(b.title))
                .map((page) => (
                  <li key={page.identity} className="flex gap-4 py-4 flex items-center justify-between">
                    <div className="flex gap-4 flex items-center">
                      {page.title && (
                        <span
                          className="text-l md:text-xl cursor-pointer"
                          onClick={() =>
                            page.is_global
                              ? setModalOpen(true)
                              : navigate(`/${marketId}/admin/pages/edit/${eventId}/${page.identity}`)
                          }
                        >
                          {page.title}
                        </span>
                      )}
                      {page.is_global && <span className="button button-notice bg-sky-100">Global</span>}
                    </div>
                    <div>
                      <button
                        className="button text-sm bg-emerald-400 text-white"
                        onClick={() => handlePublishClick(page)}
                      >
                        Publish
                      </button>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        )}
      </>
    );
  } else {
    return (
      <div className="grid grid-cols-2 gap-8">
        <span className="">
          It looks like there are currently no pages set up for this event. Please create and manage prize options
          through the add page button.
        </span>
      </div>
    );
  }
};

export default EventPage;
