import { Link, useParams } from "react-router-dom";
import useUserLoggedIn from "../../hooks/useUserLoggedIn";
import LogoutButton from "../../components/LogoutButton/LogoutButton";

const prettifyString = (str) => {
  console.log(str);
  return str
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const AmbassadorDashboard = () => {
  const { marketId } = useParams();
  useUserLoggedIn();

  const storedCreds = localStorage.getItem("collectAndWinAdminCreds");

  if (storedCreds) {
    const { eventId, eventData } = JSON.parse(storedCreds);
    const { event_type } = eventData;

    return (
      <div className="page-center">
        <LogoutButton />
        <div className="text-center">
          <h1 className="h5">{prettifyString(marketId)}</h1>
          <h2 className="h1 mt-10">{eventData.title}</h2>
          <div className="mt-30">
            {event_type === "dynamic" && (
              <Link className="button bg-sky-600 text-white mt-10" to={`/${marketId}/ambassador/generate`}>
                Generate New Game
              </Link>
            )}
            <Link className="button bg-sky-600 text-white mt-10" to={`/${marketId}/ambassador/verify`}>
              Verify Prize
            </Link>
          </div>
        </div>
      </div>
    );
  }
};

export default AmbassadorDashboard;
