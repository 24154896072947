import { BrowserRouter, Route, Routes } from "react-router-dom";

// import useEnvironmentDetection from "./hooks/useEnvironmentDetection";
import Home from "../pages/Home/Home";
// import Login from "../pages/Login/Login";
import AmbassadorLogin from "../pages/AmbassadorLogin/AmbassadorLogin";
import AdminLogin from "../pages/AdminLogin/AdminLogin";
import AmbassadorDashboard from "../pages/AmbassadorDashboard/AmbassadorDashboard";
import AdminDashboard from "../pages/AdminDashboard/AdminDashboard";
import EventList from "../pages/EventList/EventList";
import EventPage from "../pages/EventPage/EventPage";
import EventAdd from "../pages/EventAdd/EventAdd";
import EventEdit from "../pages/EventEdit/EventEdit";
import PageAdd from "../pages/PageAdd/PageAdd";
import PageEdit from "../pages/PageEdit/PageEdit";
import PagesGlobal from "../pages/PagesGlobal/PagesGlobal";
import PageGlobalAdd from "../pages/PageGlobalAdd/PageGlobalAdd";
import PageGlobalEdit from "../pages/PageGlobalEdit/PageGlobalEdit";
import PrizeAdd from "../pages/PrizeAdd/PrizeAdd";
import PrizeEdit from "../pages/PrizeEdit/PrizeEdit";
import PrizesGlobal from "../pages/PrizesGlobal/PrizesGlobal";
import PrizeGlobalEdit from "../pages/PrizeGlobalEdit/PrizeGlobalEdit";
import PrizeGlobalAdd from "../pages/PrizeGlobalAdd/PrizeGlobalAdd";
import PinsEdit from "../pages/PinsEdit/PinsEdit";
import Verify from "../pages/Verify/Verify";
import Generate from "../pages/Generate/Generate";
import LoginSelect from "../pages/LoginSelect/LoginSelect";
import Claim from "../pages/Claim/Claim";
import FourOhFour from "../pages/FourOhFour/FourOhFour";
import Map from "../pages/Map/Map";

function App() {
  // const endpoint = useEnvironmentDetection();
  const Router = BrowserRouter;

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/:marketId" exact element={<LoginSelect />} />
          <Route path="/:marketId/ambassador/login" exact element={<AmbassadorLogin />} />
          {/* <Route path="/:marketId" exact element={<Login />} /> */}
          <Route path="/:marketId/admin/login" exact element={<AdminLogin />} />
          <Route path="/:marketId/admin/dashboard" exact element={<AdminDashboard />} />
          <Route path="/:marketId/events" exact element={<EventList />} />
          <Route path="/:marketId/events/:eventId" exact element={<EventPage />} />
          <Route path="/:marketId/events/add" exact element={<EventAdd />} />
          <Route path="/:marketId/events/edit/:eventId" exact element={<EventEdit />} />
          <Route path="/:marketId/admin/pages/add/:eventId" exact element={<PageAdd />} />
          <Route path="/:marketId/admin/pages/edit/:eventId/:pageId" exact element={<PageEdit />} />
          <Route path="/:marketId/admin/pages/global" exact element={<PagesGlobal />} />
          <Route path="/:marketId/admin/pages/global/add" exact element={<PageGlobalAdd />} />
          <Route path="/:marketId/admin/pages/global/edit/:pageId" exact element={<PageGlobalEdit />} />
          <Route path="/:marketId/admin/prizes/" exact element={<PrizesGlobal />} />
          <Route path="/:marketId/admin/prizes/edit/:prizeId" exact element={<PrizeGlobalEdit />} />
          <Route path="/:marketId/admin/prizes/add" exact element={<PrizeGlobalAdd />} />
          <Route path="/:marketId/events/:eventId/prizes/add" exact element={<PrizeAdd />} />
          <Route path="/:marketId/admin/prizes/edit/:eventId/:prizeId" exact element={<PrizeEdit />} />
          <Route path="/:marketId/admin/:eventId/pins/edit" exact element={<PinsEdit />} />
          <Route path="/:marketId/ambassador/dashboard" exact element={<AmbassadorDashboard />} />
          <Route path="/:marketId/ambassador/verify" exact element={<Verify />} />
          <Route path="/:marketId/ambassador/generate" exact element={<Generate />} />
          <Route path="/:marketId/ambassador/claim" exact element={<Claim />} />
          <Route path="/map-test" exact element={<Map />} />

          <Route path="*" element={<FourOhFour />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
